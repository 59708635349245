import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Hidden } from '@material-ui/core';
import { blue } from '@serviceslabs/material-ui-pro/colors';

import { useSize } from '../../util';

const navHeight = 64;
const minHeight = 450;

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    desktopOverlayContainer: {
        display: 'flex',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    desktopOverlay: {
        boxSizing: 'border-box',
        height: `calc(100% - ${navHeight}px)`,
        minHeight: `${minHeight}`,
        flex: '0 0 50%',
        maxWidth: '50%',
        borderLeft: '0px solid transparent',
        opacity: 0.5,
        minWidth: `${minHeight}`,
        borderRight: (props) => `${props.width * 0.2}px solid transparent`,
        borderBottom: (props) =>
            `${props.adjustedHeight - navHeight}px solid ${blue[800]}`,
        [theme.breakpoints.down('md')]: {
            flex: '0 0 55%',
            maxWidth: '55%',
        },
    },
    mobileOverlayContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: '100%',
    },
    spacer: {
        flex: '1 1 auto',
    },
    mobileTriangle: {
        boxSizing: 'border-box',
        width: '100%',
        flex: '0 0 20%',
        borderLeft: '0px solid transparent',
        opacity: 0.5,
        borderRight: (props) => `${props.width - 0}px solid transparent`,
        borderBottom: (props) =>
            `${props.adjustedHeight * 0.2}px solid ${blue[800]}`,
        [theme.breakpoints.down('xs')]: {
            flex: '0 0 50%',
        },
    },
    mobileOverlay: {
        backgroundColor: blue[800],
        opacity: 0.5,
        width: '100%',
        flex: '0 0 20%',
        [theme.breakpoints.down('xs')]: {
            flex: '0 0 50%',
        },
    },
}));

function HeroOverlay(props) {
    const { children, ...otherProps } = props;
    const { width, height } = useSize();
    const peekHeight = Math.max(
        minHeight + navHeight,
        Math.floor(height * 0.9)
    );
    const classes = useStyles({
        width,
        adjustedHeight: peekHeight,
        classes: otherProps.classes,
    });

    return (
        <div className={classes.root}>
            {children}
            <Hidden smDown>
                <div className={classes.desktopOverlayContainer}>
                    <div className={classes.desktopOverlay} />
                </div>
            </Hidden>
            <Hidden mdUp>
                <div className={classes.mobileOverlayContainer}>
                    <div className={classes.spacer} />
                    <div className={classes.mobileTriangle} />
                    <div className={classes.mobileOverlay} />
                </div>
            </Hidden>
        </div>
    );
}

HeroOverlay.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
};

HeroOverlay.defaultProps = {};

export default HeroOverlay;
