const defaultFluidSrcSetMultipliers = [0.25, 0.5, 1, 2];
const defaultFixedSrcSetMultipliers = [1, 1.5, 2];

export function calculateImgixFluidSrc(origUrl, width) {
    const url = new URL(origUrl);

    url.searchParams.set('w', width);
    url.searchParams.set('fit', 'clip');

    return url.href;
}

export function calculateImgixFluidSrcSet(origUrl, maxWidth, options) {
    const { srcSetMultipliers } = options || {};

    return (srcSetMultipliers || defaultFluidSrcSetMultipliers)
        .map((mx) => {
            const multipliedWidth = Math.round(maxWidth * mx);
            const href = calculateImgixFluidSrc(origUrl, multipliedWidth);

            return `${href} ${multipliedWidth}w`;
        })
        .join(', ');
}

export function calculateImgixFixedSrc(origUrl, width, height, multiplier) {
    if (!width && !height) {
        return origUrl;
    }

    const url = new URL(origUrl);

    if (width) {
        const multipliedWidth = Math.round(width * multiplier);

        url.searchParams.set('w', multipliedWidth);
        url.searchParams.set('fit', 'clip');
    }

    if (height) {
        const multipliedHeight = Math.round(height * multiplier);

        url.searchParams.set('h', multipliedHeight);
        url.searchParams.set('fit', 'clip');
    }

    return url.href;
}

export function calculateImgixFixedSrcSet(origUrl, options) {
    const { srcSetMultipliers, width, height } = options || {};

    if (!width && !height) {
        return origUrl;
    }

    return (srcSetMultipliers || defaultFixedSrcSetMultipliers)
        .map((mx) => {
            const href = calculateImgixFixedSrc(origUrl, width, height, mx);
            return `${href} ${mx}x`;
        })
        .join(', ');
}

export function addImgixParams(origUrl, params) {
    if (!params || !isImgixUrl(origUrl)) {
        return origUrl;
    }

    const url = new URL(origUrl);
    Object.keys(params).forEach((key) => {
        url.searchParams.set(key, params[key]);
    });

    return url.href;
}

export function isImgixUrl(imgUrl) {
    if (!imgUrl) {
        return false;
    }

    try {
        const url = new URL(imgUrl);
        return url.hostname.startsWith('images.prismic.io');
    } catch (err) {
        return false;
    }
}
