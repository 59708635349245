import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Typography, TextField, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { FixedImage } from '../Image';
import { RichText } from '../prismic';
import { postOutOfAreaSignUp } from '../../../api/outOfAreaSignUps';

const useStyles = makeStyles((theme) => ({
    emailSignUpContainer: {
        marginTop: theme.spacing(1),
    },
    submitButtonContainer: {
        display: 'flex',
        alignItems: 'stretch',
    },
    partnerReferenceLabel: {
        marginTop: theme.spacing(4),
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(10),
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap',
        },
    },
    logo: {
        margin: theme.spacing(2, 5),
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    thankYouMessage: {
        marginTop: theme.spacing(3),
        fontWeight: 500,
    },
    errorMessage: {
        color: theme.palette.error.main,
    },
}));

function BadZipCode({
    zipCode,
    labels: {
        signUpLabelHtml,
        ctaButtonText,
        partnerReferenceLabelHtml,
        thankYouMessageLabelHtml,
    },
    partnerLinks,
}) {
    const classes = useStyles();
    const [emailAddress, setEmailAddress] = useState('');
    const [submissionError, setSubmissionError] = useState();
    const [hasSubmitted, setHasSubmitted] = useState(false);

    async function handleSubmitSignUp() {
        try {
            setSubmissionError(undefined);

            await postOutOfAreaSignUp({
                emailAddress,
                zipCode,
            });

            setHasSubmitted(true);
        } catch (err) {
            setSubmissionError(
                'Uh oh! There was an error submitting your request. Please try again or contact support@pro.com if the problem persists.'
            );
        }
    }

    return (
        <>
            {hasSubmitted ? (
                <Typography
                    variant="body2"
                    component="div"
                    className={classes.thankYouMessage}
                    id="not-serviced-sign-up-confirmation"
                >
                    <RichText html={thankYouMessageLabelHtml} />
                </Typography>
            ) : (
                <>
                    <Typography variant="body2" component="div">
                        <RichText html={signUpLabelHtml} />
                    </Typography>
                    <Grid
                        container
                        spacing={2}
                        className={classes.emailSignUpContainer}
                    >
                        <Grid item xs={12} sm={8}>
                            <TextField
                                autoFocus
                                key="emailAddress"
                                label="Email Address"
                                value={emailAddress}
                                onChange={(event) =>
                                    setEmailAddress(event.target.value)
                                }
                                onKeyPress={(event) =>
                                    event.key === 'Enter' &&
                                    handleSubmitSignUp()
                                }
                                variant="filled"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={4}
                            className={classes.submitButtonContainer}
                        >
                            <Button
                                variant="contained"
                                onClick={handleSubmitSignUp}
                                onKeyPress={(event) =>
                                    event.key === 'Enter' &&
                                    handleSubmitSignUp()
                                }
                                disabled={!emailAddress}
                                color="secondary"
                            >
                                {ctaButtonText}
                            </Button>
                        </Grid>
                        {submissionError ? (
                            <Grid item xs={12}>
                                <Typography
                                    id="not-serviced-sign-up-error"
                                    className={classes.errorMessage}
                                    variant="body2"
                                    component="div"
                                >
                                    {submissionError}
                                </Typography>
                            </Grid>
                        ) : undefined}
                    </Grid>
                </>
            )}
            <Typography
                variant="body2"
                component="div"
                className={classes.partnerReferenceLabel}
            >
                <RichText html={partnerReferenceLabelHtml} />
            </Typography>
            <div className={classes.logoContainer}>
                {partnerLinks.map(({ link, logoImage }) => (
                    <div className={classes.logo} key={logoImage.url}>
                        <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FixedImage
                                image={logoImage}
                                height={25}
                                loading="eager"
                                fadeIn={false}
                            />
                        </a>
                    </div>
                ))}
            </div>
        </>
    );
}

BadZipCode.propTypes = {
    zipCode: PropTypes.string.isRequired,
    labels: PropTypes.shape({
        signUpLabelHtml: PropTypes.string,
        ctaButtonText: PropTypes.string,
        partnerReferenceLabelHtml: PropTypes.string,
        thankYouMessageLabelHtml: PropTypes.string,
    }).isRequired,
    partnerLinks: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.string,
            logoImage: PropTypes.object,
        })
    ),
};

BadZipCode.defaultProps = {
    partnerLinks: [],
};

export default BadZipCode;
