import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { FixedImage } from '../Image';
import Caption from '../Caption';

const useTeamItemStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    image: {
        borderRadius: '50%',
        marginBottom: theme.spacing(1),
        height: 140,
        width: 140,
    },
    memberName: {
        fontSize: 22,
        fontWeight: 600,
        letterSpacing: 0.55,
        marginBottom: theme.spacing(0),
    },
    memberTitle: {
        marginBottom: theme.spacing(2),
        fontWeight: 300,
    },
    memberDescription: {
        '& p': {
            margin: 0,
            paddingTop: theme.spacing(1),
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
    },
}));

function TeamItem({ image, name, bio, title, ...otherProps }) {
    const classes = useTeamItemStyles({ classes: otherProps.classes });

    return (
        <Grid item sm={4} xs={12} className={classes.root}>
            <FixedImage
                className={classes.image}
                image={image}
                width={140}
                height={140}
            />
            <Caption caption={name} classes={{ root: classes.memberName }} />
            {title ? (
                <Caption
                    caption={title}
                    classes={{ root: classes.memberTitle }}
                />
            ) : undefined}
            <Caption
                caption={bio}
                classes={{ root: classes.memberDescription }}
            />
        </Grid>
    );
}

TeamItem.propTypes = {
    image: PropTypes.object.isRequired,
    bio: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
};

TeamItem.defaultProps = {
    title: undefined,
};

export default TeamItem;
