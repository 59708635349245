import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { DialogTitle, IconButton, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import blue from '@serviceslabs/material-ui-pro/colors/blue';

const useDialogHeaderStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: blue[300],
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'center',
        paddingTop: theme.spacing(6),
    },
    title: {
        color: theme.palette.common.white,
        fontWeight: 600,
    },
    instructionText: {
        color: theme.palette.common.white,
        fontWeight: 300,
        paddingTop: theme.spacing(2),
        margin: '0 auto',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.common.white,
    },
}));

function DialogHeader({ onClose, title, instructionText, ...otherProps }) {
    const classes = useDialogHeaderStyles({ classes: otherProps.classes });

    return (
        <DialogTitle disableTypography className={classes.root}>
            <Typography variant="h5" className={classes.title}>
                {title}
            </Typography>
            {instructionText && (
                <Typography
                    className={classes.instructionText}
                    variant="body1"
                    component="div"
                >
                    {instructionText}
                </Typography>
            )}
            <IconButton
                aria-label="Close"
                className={classes.closeButton}
                onClick={onClose}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    );
}

DialogHeader.propTypes = {
    title: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    instructionText: PropTypes.node,
    classes: PropTypes.object,
};

DialogHeader.defaultProps = {
    title: 'Request a Quote',
    classes: undefined,
    instructionText: undefined,
};

export default DialogHeader;
