import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { grey } from '@serviceslabs/material-ui-pro/colors';
import classnames from 'classnames';

import Section from './Section';
import SectionHeader from './SectionHeader';
import Carousel, { CarouselContent } from './Carousel';

import openQuoteSvg from '../../images/quote_open.svg';
import closeQuoteSvg from '../../images/quote_close.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    title: {
        marginBottom: theme.spacing(7),
    },
    carousel: {},
    carouselContent: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    stylizedQuote: {},
    leftQuote: {
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(-2),
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(2),
        },
        alignSelf: 'flex-start',
    },
    rightQuote: {
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(-1),
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
        marginLeft: theme.spacing(2),
        alignSelf: 'flex-end',
    },
    quote: {
        textAlign: 'center',
        fontWeight: 200,
        fontFamily: 'Gibson',
        fontSize: 24,
        lineHeight: 1.2,
        display: 'flex',
        justifyContent: 'flex-start',
        flexGrow: 0,
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            textAlign: 'left',
        },
    },
    customerName: {
        margin: theme.spacing(3, 0),
        textAlign: 'center',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        fontFamily: 'Gibson',
        fontSize: 24,
        fontWeight: 400,
        color: grey[500],
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
            fontSize: 18,
        },
    },
}));

function QuoteTestimonials({ title, testimonials, ...otherProps }) {
    const classes = useStyles({ classes: otherProps.classes });
    return (
        <Section classes={{ root: classes.root }}>
            {title && (
                <SectionHeader
                    title={title}
                    classes={{ root: classes.title }}
                />
            )}
            <Carousel classes={{ root: classes.carousel }}>
                {testimonials.map((testimonial, i) => (
                    <CarouselContent
                        tabIndex={i + 1}
                        key={testimonial.customerName}
                        classes={{ container: classes.carouselContent }}
                    >
                        <Typography
                            component="blockquote"
                            className={classes.quote}
                        >
                            <img
                                src={openQuoteSvg}
                                alt="open quotation mark"
                                className={classnames(
                                    classes.stylizedQuote,
                                    classes.leftQuote
                                )}
                            />
                            {testimonial.quote}
                            <img
                                src={closeQuoteSvg}
                                alt="close quotation mark"
                                className={classnames(
                                    classes.stylizedQuote,
                                    classes.rightQuote
                                )}
                            />
                        </Typography>

                        <Typography className={classes.customerName}>
                            {testimonial.customerName}
                        </Typography>
                    </CarouselContent>
                ))}
            </Carousel>
        </Section>
    );
}

QuoteTestimonials.propTypes = {
    testimonials: PropTypes.arrayOf(
        PropTypes.shape({
            customerName: PropTypes.string,
            quote: PropTypes.string.isRequired,
        })
    ).isRequired,
    title: PropTypes.string,
};

QuoteTestimonials.defaultProps = {
    title: undefined,
};

export default QuoteTestimonials;
