import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { arrayOfInts } from '../../../util/array';

import ProgressDot from './ProgressDot';

const useStyles = makeStyles(() => ({
    root: {},
    progressIndicator: {
        margin: '0 auto',
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        listStyle: 'none',
    },
}));

function CarouselProgressDots(props) {
    const { current, length, setIndex, ...otherProps } = props;
    const classes = useStyles({ classes: otherProps.classes });

    return (
        <ol className={classes.progressIndicator}>
            {arrayOfInts(length).map((i) => (
                <ProgressDot
                    key={`progress_dot_${i}`}
                    selected={i === current}
                    onSelect={() => setIndex(i)}
                />
            ))}
        </ol>
    );
}

CarouselProgressDots.propTypes = {
    classes: PropTypes.object,
    current: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired,
    setIndex: PropTypes.func,
};

CarouselProgressDots.defaultProps = {
    classes: undefined,
    setIndex: () => {},
};

export default CarouselProgressDots;
