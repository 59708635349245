import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import website from '../../../config/website';

function validateJsonLD(json) {
    try {
        return JSON.stringify(JSON.parse(json));
    } catch (err) {
        return undefined;
    }
}

const query = graphql`
    query SEOQuery {
        prismicMetadataHelmet {
            data {
                description {
                    text
                }
                image {
                    localFile {
                        childImageSharp {
                            fixed(width: 1200, height: 630) {
                                src
                            }
                        }
                    }
                }
                siteName {
                    text
                }
                title {
                    text
                }
                twitterSummary {
                    text
                }
                twitterUsername {
                    text
                }
                url {
                    text
                }
                jsonLD {
                    text
                }
            }
        }
    }
`;

function StaticSEO({
    description: customDescription,
    title: customTitle,
    jsonLD: customJsonLD,
}) {
    return (
        <StaticQuery
            query={query}
            render={({
                prismicMetadataHelmet: {
                    data: {
                        description: defaultDescription,
                        title: defaultTitle,
                        image,
                        siteName,
                        twitterSummary,
                        twitterUsername,
                        url,
                        jsonLD: defaultJsonLD,
                    },
                },
            }) => {
                const description =
                    customDescription || defaultDescription.text;
                const title = customTitle || defaultTitle.text;
                const imageUrl = `${website.url}${image.localFile.childImageSharp.fixed.src}`;
                const jsonLD = validateJsonLD(
                    customJsonLD || defaultJsonLD.text
                );

                return (
                    <Helmet
                        htmlAttributes={{
                            lang: website.siteLanguage,
                        }}
                    >
                        {jsonLD ? (
                            <script type="application/ld+json">{jsonLD}</script>
                        ) : undefined}
                        <title>{title}</title>
                        <meta name="description" content={description} />
                        <meta property="og:url" content={url.text} />
                        <meta property="og:title" content={title} />
                        <meta property="og:description" content={description} />
                        <meta property="og:site_name" content={siteName.text} />
                        <meta property="og:image" content={imageUrl} />
                        <meta
                            name="twitter:card"
                            content={twitterSummary.text}
                        />
                        <meta
                            name="twitter:site"
                            content={twitterUsername.text}
                        />
                        {/*
                            https://ahrefs.com/ is a crawler. Adding this meta tag helps verify our site with them
                            so we can enable faster crawling and advanced features.
                        */}
                        <meta
                            name="ahrefs-site-verification"
                            content="f0aab8c7a68767a32cb2fe027c9a5eeb76afdc3afee63cdad300bd2fb6e38f24"
                        />
                    </Helmet>
                );
            }}
        />
    );
}

StaticSEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    jsonLD: PropTypes.string,
};

StaticSEO.defaultProps = {
    title: undefined,
    description: undefined,
    jsonLD: undefined,
};

export default StaticSEO;
