import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 1,
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(16),
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 5),
        },
        width: '100%',
        [theme.breakpoints.down('md')]: {
            alignSelf: 'flex-end',
        },
    },
    h1: {
        textAlign: 'left',
        [theme.breakpoints.up('md')]: {
            width: '45%',
            minWidth: 400,
        },
        color: theme.palette.common.white,
        textShadow: '0 2px 12px #000',
    },
    h2: {
        textAlign: 'left',
        color: theme.palette.common.white,
        [theme.breakpoints.up('md')]: {
            width: '30%',
            minWidth: 300,
        },
        textShadow: '0 2px 12px #000',
    },
    button: {
        left: 0,
        marginTop: theme.spacing(3),
    },
}));

function HeroText(props) {
    const { title, subtitle, ctaComponent } = props;
    const classes = useStyles(props);

    return (
        <div className={classes.root}>
            {title && (
                <Typography className={classes.h1} variant="h2">
                    {title}
                </Typography>
            )}
            {subtitle && (
                <Typography className={classes.h2} variant="h4">
                    {subtitle}
                </Typography>
            )}
            {ctaComponent && (
                <div className={classes.button}>{ctaComponent}</div>
            )}
        </div>
    );
}

HeroText.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.node,
    ctaComponent: PropTypes.object,
};

HeroText.defaultProps = {
    title: undefined,
    subtitle: undefined,
    ctaComponent: undefined,
};

export default HeroText;
