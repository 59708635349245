import getWindow from './window';

/**
 * This finds the preview data stored on the `window` object that was
 * saved by the `gatsby-source-prismic` from prismic's previe token URL.
 * It will choose to either return the static data as generated at build
 * time or the preview data if applicable.
 *
 * NOTE: in the `gatsby-source-prismic` documentation, it recommends using
 * its `mergePrismicPreviewData` function, which in turn uses lodash's
 * merge function. I (Troy) found this to cause major performance issues
 * that was eating up gigabytes of memory and failing to load pages. Since
 * the preview data is a superset of the static data, and we treat the data
 * from prismic as immutable, I decided to just return the objects wholesale.
 *
 * @param {*} staticData
 */
export function withPreviewData(staticData) {
    const window = getWindow();
    // eslint-disable-next-line no-underscore-dangle
    const previewData = window ? window.__PRISMIC_PREVIEW_DATA__ : {};

    // No preview data? No problem. Just return the data from the build.
    if (!previewData) {
        return staticData;
    }

    // There won't be any static data on unpublished pages that we're previewing
    // before they've been published. In that case, the preview data is returned.
    if (!staticData) {
        return previewData;
    }

    // Aside from top-level pages and templates, there are several widgets that make
    // static queries for data, so sometimes the preview data won't be meant for the
    // caller of this function. We'll distinquish that by comparing the top level
    // keys in the static data and the preview data to see if they're the same.
    //
    // For example, the static data for { data: prismicNavBar: { ... } } won't match
    // preview data for the homepage, like { data: prismicHomepage: { ... } }, so we
    // won't return the homepage preview data for the nav bar.
    const staticKeys = new Set(Object.keys(staticData));
    const previewKeys = Object.keys(previewData);

    const previewOverridesStatic = Boolean(
        previewKeys.find((key) => staticKeys.has(key))
    );

    return previewOverridesStatic ? previewData : staticData;
}
