import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        padding: theme.spacing(4),
    },
    input: {},
}));

function isNumber(val) {
    return /^\d+$/.test(val);
}

const ZipCode = ({
    zipCode,
    onUpdateZipCode,
    onEnter,
    textFieldProps,
    textFieldComponent,
    ...otherProps
}) => {
    const classes = useStyles({ classes: otherProps.classes });
    const Component = textFieldComponent;

    return (
        <div className={classes.root}>
            <Component
                autoFocus
                className={classes.input}
                margin="dense"
                variant="filled"
                label="Zip Code"
                {...textFieldProps}
                value={zipCode}
                onChange={(event) => {
                    const {
                        target: { value },
                    } = event;

                    if (!value || isNumber(value)) {
                        onUpdateZipCode(value);
                    }
                }}
                onKeyPress={(event) =>
                    event.key === 'Enter' && zipCode && onEnter()
                }
            />
        </div>
    );
};

ZipCode.propTypes = {
    zipCode: PropTypes.string,
    onUpdateZipCode: PropTypes.func.isRequired,
    onEnter: PropTypes.func.isRequired,
    textFieldProps: PropTypes.object,
    textFieldComponent: PropTypes.elementType,
};

ZipCode.defaultProps = {
    zipCode: '',
    textFieldProps: {},
    textFieldComponent: TextField,
};

export default ZipCode;
