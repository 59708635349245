import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import Caption from '../Caption';
import Section from '../Section';
import SectionHeader from '../SectionHeader';
import Divider from '../Divider';

import TeamItem from './TeamItem';

const useTeamStyles = makeStyles((theme) => ({
    root: {},
    gridContainer: {
        justifyContent: 'center',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    caption: {
        textAlign: 'center',
    },
}));

function Team({ title, subtitle, color, items, ...otherProps }) {
    const classes = useTeamStyles({ classes: otherProps.classes });

    return (
        <Section color={color} classes={{ root: classes.root }}>
            {title ? <SectionHeader title={title} /> : undefined}
            {subtitle ? (
                <Caption
                    caption={subtitle}
                    classes={{ root: classes.caption }}
                />
            ) : undefined}

            {items.length ? (
                <Grid
                    container
                    spacing={10}
                    alignItems="flex-start"
                    className={classes.gridContainer}
                >
                    {items.map((item, i) => (
                        <TeamItem
                            key={`image_${i + 1}`}
                            image={item.image}
                            bio={item.bioHtml}
                            name={item.name}
                            title={item.title}
                        />
                    ))}
                </Grid>
            ) : undefined}

            <Divider width="100%" />
        </Section>
    );
}

Team.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    color: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Team.defaultProps = {
    color: undefined,
    title: undefined,
    subtitle: undefined,
};

export default Team;
