import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'left',
    },
    number: {
        color: theme.palette.grey[200],
        [theme.breakpoints.down('sm')]: {
            fontSize: 48,
        },
    },
}));

function Statistics(props) {
    const { statistics, ...otherProps } = props;
    const classes = useStyles({ classes: otherProps.classes });

    return (
        <Grid container className={classes.root}>
            {statistics.map(({ number, caption }) => (
                <Grid item xs={12} key={caption}>
                    <Typography variant="h1" className={classes.number}>
                        {number.text}
                    </Typography>
                    <Typography variant="body1">{caption.text}</Typography>
                </Grid>
            ))}
        </Grid>
    );
}

Statistics.propTypes = {
    statistics: PropTypes.arrayOf(
        PropTypes.shape({
            number: PropTypes.shape({
                text: PropTypes.string.isRequired,
            }),
            caption: PropTypes.shape({
                text: PropTypes.string.isRequired,
            }),
        })
    ),
    classes: PropTypes.object,
};

Statistics.defaultProps = {
    statistics: [],
    classes: undefined,
};

export default Statistics;
