import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import ValuePropCallouts from './ValuePropCallouts';
import { parseValuePropCallouts } from '../../util/parse';
import { withPreviewData } from '../../util/preview';

const query = graphql`
    query ValuePropsQuery {
        prismicValueCallouts {
            data {
                valuePropCallouts {
                    title {
                        text
                    }
                    description {
                        html
                    }
                    icon {
                        ...ProPrismicImageMetadata_NoBase64
                        localFile {
                            childImageSharp {
                                fixed(width: 400) {
                                    ...GatsbyImageSharpFixed_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

function StaticValueCallouts({ title }) {
    return (
        <StaticQuery
            query={query}
            render={(staticData) => {
                const {
                    prismicValueCallouts: {
                        data: { valuePropCallouts },
                    },
                } = withPreviewData(staticData);

                return (
                    <ValuePropCallouts
                        title={title}
                        callouts={parseValuePropCallouts(valuePropCallouts)}
                    />
                );
            }}
        />
    );
}

StaticValueCallouts.propTypes = {
    title: PropTypes.string,
};

StaticValueCallouts.defaultProps = {
    title: '',
};

export default StaticValueCallouts;
