import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { bindKeyboard } from 'react-swipeable-views-utils';
import { makeStyles } from '@material-ui/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Grid, Button } from '@material-ui/core';

import CarouselProgressDots from './CarouselProgressDots';

const useStyles = makeStyles((theme) => ({
    root: {},
    contentContainer: {
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    button: {
        position: 'relative',
        height: '100%',
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        '&:hover': {
            background: 'transparent',
        },
    },
    buttonHoverOverlay: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        height: theme.spacing(8),
        width: theme.spacing(8),
        borderRadius: '50%',
        '&:hover': {
            backgroundColor: fade(
                theme.palette.text.primary,
                theme.palette.action.hoverOpacity
            ),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
}));

export const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews);

function Carousel({
    children,
    showProgressDots,
    classes: propClasses,
    ...otherProps
}) {
    const classes = useStyles({ classes: propClasses });
    const [index, setIndex] = useState(0);
    const numChildren = React.Children.count(children);
    const hasMultipleChildren = numChildren > 1;

    // There are cases where the number of pages changes with the screen size
    // so we'll guard against index out of bounds by clamping the index if
    // the number of children changes.
    useEffect(() => {
        if (index > numChildren - 1) {
            setIndex(numChildren - 1);
        }
    }, [index, numChildren]);

    const contentGridSizes = hasMultipleChildren
        ? {
              xs: 8,
              sm: 8,
              md: 10,
          }
        : { xs: 12 };

    function handleSetIndex(next) {
        const i = index + next;
        const max = children.length - 1;
        if (i > max) setIndex(0);
        else if (i < 0) setIndex(max);
        else setIndex(i);
    }

    return (
        <Grid container spacing={0} className={classes.root}>
            {hasMultipleChildren && (
                <Grid item xs={2} sm={2} md={1}>
                    <Button
                        aria-label="Previous"
                        className={classes.button}
                        disableRipple
                        disabled={
                            false /* buttons should never be disabled so we can scroll infinitly */
                        }
                        onClick={() => handleSetIndex(-1)}
                    >
                        <span className={classes.buttonHoverOverlay}>
                            <ChevronLeftIcon />
                        </span>
                    </Button>
                </Grid>
            )}
            <Grid
                item
                {...contentGridSizes}
                className={classes.contentContainer}
            >
                {hasMultipleChildren ? (
                    <BindKeyboardSwipeableViews
                        index={index}
                        onChangeIndex={setIndex}
                        {...otherProps}
                    >
                        {children}
                    </BindKeyboardSwipeableViews>
                ) : (
                    <>{children}</>
                )}
                {showProgressDots && hasMultipleChildren && (
                    <CarouselProgressDots
                        current={index}
                        length={React.Children.count(children)}
                        setIndex={setIndex}
                    />
                )}
            </Grid>
            {hasMultipleChildren && (
                <Grid item xs={2} sm={2} md={1}>
                    <Button
                        aria-label="Next"
                        className={classes.button}
                        disableRipple
                        disabled={
                            false /* buttons should never be disabled so we can scroll infinitly */
                        }
                        onClick={() => handleSetIndex(1)}
                    >
                        <span className={classes.buttonHoverOverlay}>
                            <ChevronRightIcon />
                        </span>
                    </Button>
                </Grid>
            )}
        </Grid>
    );
}

Carousel.propTypes = {
    classes: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
    showProgressDots: PropTypes.bool,
};

Carousel.defaultProps = {
    classes: undefined,
    showProgressDots: true,
};

export default Carousel;
