import React from 'react';
import PropTypes from 'prop-types';
import website from '../../../config/website';

// https://a11yproject.com/posts/skip-nav-links/
const SkipNavLink = ({ text }) => (
    <a href={`#${website.skipNavId}`} aria-label={text} data-reach-skip-link>
        {text}
    </a>
);

export default SkipNavLink;

SkipNavLink.propTypes = {
    text: PropTypes.string,
};

SkipNavLink.defaultProps = {
    text: 'Skip to main content',
};
