import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { DialogContent } from '@material-ui/core';
import DialogHeader from './DialogHeader';

const useStyles = makeStyles(() => ({
    header: {},
    content: {
        overflowY: 'visible',
    },
}));

export default function DialogPage({
    content,
    footer,
    onClose,
    title,
    headerText,
    ...otherProps
}) {
    const classes = useStyles({ classes: otherProps.classes });

    return (
        <>
            <DialogHeader
                title={title}
                className={classes.header}
                onClose={onClose}
                instructionText={headerText}
            />
            <DialogContent className={classes.content}>{content}</DialogContent>
            {footer}
        </>
    );
}

DialogPage.propTypes = {
    content: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
    footer: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    title: PropTypes.string,
    headerText: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

DialogPage.defaultProps = {
    title: 'Request a Quote',
    footer: undefined,
    headerText: undefined,
};
