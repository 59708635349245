import axios from 'axios';

import { getGlobal } from '../util/globals';

const apiUrl = () => getGlobal('apiUrl');

export async function isValidZipCode(zipCode) {
    const res = await axios.get(`${apiUrl()}/zip-codes/${zipCode}/is-serviced`);
    return res.data.isServiced;
}
