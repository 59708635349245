import axios from 'axios';

import { getGlobal } from '../util/globals';

const apiUrl = () => getGlobal('apiUrl');

export async function postOutOfAreaSignUp({ emailAddress, zipCode }) {
    const result = await axios.post(`${apiUrl()}/out-of-area-sign-ups/`, {
        emailAddress,
        zipCode,
    });

    return result.data;
}
