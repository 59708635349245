import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import PreviewThumbnail from './PreviewThumbnail';

const useStyles = makeStyles(() => ({
    video: {
        objectFit: 'cover',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        border: 'none',
    },
    previewThumbnail: {},
}));

const embedYoutubeVideoIdRegex = /.*embed\/([^/]+).*$/;
const watchYoutubeVideoIdRegex = /.*watch\?v=([^/&]+).*$/;
function extractYoutubeId(link) {
    let tag = link.match(embedYoutubeVideoIdRegex);
    if (tag && tag.length > 1) {
        return tag[1];
    }

    tag = link.match(watchYoutubeVideoIdRegex);
    if (tag && tag.length > 1) {
        return tag[1];
    }

    return undefined;
}

function getAutoplayYoutubeLink(videoId) {
    const url = new URL(`https://www.youtube.com/embed/${videoId}`);

    // Since we're adding our own preview thumbnail to click,
    // we want the youtube video to play automatically once
    // loaded. So we'll add the autoplay=1 query parameter.
    url.searchParams.set('autoplay', '1');

    // Youtube videos show other recommended videos after the video
    // finishes playing. Setting rel=0 will make sure those recommened
    // videos are from our channel.
    url.searchParams.set('rel', '0');

    return url.href;
}

function EmbeddedYoutubeVideo({ link, title, ...otherProps }) {
    const videoId = extractYoutubeId(link);
    const classes = useStyles({ classes: otherProps.classes });
    const [isPreviewClicked, setPreviewClicked] = useState(false);

    return videoId && !isPreviewClicked ? (
        <PreviewThumbnail
            videoId={videoId}
            onClick={() => setPreviewClicked(true)}
            title={title}
            classes={{ root: classes.previewThumbnail }}
        />
    ) : (
        <iframe
            title={title}
            className={classes.video}
            src={videoId ? getAutoplayYoutubeLink(videoId) : link}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    );
}

EmbeddedYoutubeVideo.propTypes = {
    link: PropTypes.string.isRequired,
    title: PropTypes.string,
};

EmbeddedYoutubeVideo.defaultProps = {
    title: '',
};

export default EmbeddedYoutubeVideo;
