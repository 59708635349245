import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';

import Section from './Section';
import SectionHeader from './SectionHeader';
import ImageCard from './ImageCard';

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        marginBottom: theme.spacing(3),
    },
    subtitle: {
        fontWeight: 300,
    },
    grid: {
        margin: '0 auto',
    },
    gridItem: {},
    imageContainer: {
        [theme.breakpoints.up('md')]: {
            width: 'calc(100% - 18px)',
        },
    },
    captionTitle: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    paragraph: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
}));

function ValuePropCallouts({ title, callouts, valuePropsSectionTitle }) {
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Section color="primary" classes={{ root: classes.root }}>
            <SectionHeader title={title} classes={{ root: classes.title }} />
            {valuePropsSectionTitle && (
                <Typography variant="h2">{valuePropsSectionTitle}</Typography>
            )}
            <Grid container spacing={0} className={classes.grid}>
                {callouts.map((callout, i) => (
                    <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.gridItem}
                        key={`service_type_${i + 1}`}
                    >
                        <ImageCard
                            image={callout.iconImage}
                            imageWidth={400}
                            captionTitle={callout.title}
                            caption={callout.description}
                            linkTitle={callout.linkTitle}
                            link={callout.link}
                            classes={{
                                root: classes.imageContainer,
                                captionTitle: classes.captionTitle,
                                paragraph: classes.paragraph,
                            }}
                            imageStyles={
                                isSmallScreen
                                    ? {
                                          paddingTop: 400,
                                          maxWidth: 321,
                                      }
                                    : {
                                          paddingTop: '124%',
                                      }
                            }
                        />
                    </Grid>
                ))}
            </Grid>
        </Section>
    );
}

ValuePropCallouts.propTypes = {
    title: PropTypes.string.isRequired,
    valuePropsSectionTitle: PropTypes.string,
    callouts: PropTypes.arrayOf(
        PropTypes.shape({
            iconImage: PropTypes.object,
            description: PropTypes.string,
            linkTitle: PropTypes.string,
            link: PropTypes.string,
        })
    ),
};

ValuePropCallouts.defaultProps = {
    callouts: [],
    valuePropsSectionTitle: undefined,
};

export default ValuePropCallouts;
