import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Img from 'gatsby-image/withIEPolyfill';

import { makeFixed } from '../../../util/gatsbyImage';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        '&:hover $playButtonBackgroundSvg': {
            fill: theme.palette.primary.main,
            opacity: 1,
        },
    },
    previewImage: {
        cursor: 'pointer',
    },
    playButton: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        width: '68px',
        height: '48px',
        marginLeft: '-34px',
        marginTop: '-24px',
        '-moz-transition': 'opacity .25s cubic-bezier(0.0,0.0,0.2,1)',
        '-webkit-transition': 'opacity .25s cubic-bezier(0.0,0.0,0.2,1)',
        transition: 'opacity .25s cubic-bezier(0.0,0.0,0.2,1)',
        zIndex: 63,
        border: 'none',
        backgroundColor: 'transparent',
        padding: 0,
        cursor: 'pointer',
    },
    playButtonBackgroundSvg: {
        '-moz-transition':
            'fill .1s cubic-bezier(0.4,0.0,1,1),fill-opacity .1s cubic-bezier(0.4,0.0,1,1)',
        '-webkit-transition':
            'fill .1s cubic-bezier(0.4,0.0,1,1),fill-opacity .1s cubic-bezier(0.4,0.0,1,1)',
        transition:
            'fill .1s cubic-bezier(0.4,0.0,1,1),fill-opacity .1s cubic-bezier(0.4,0.0,1,1)',
        fill: theme.palette.grey[800],
        opacity: 0.8,
    },
}));

// Youtube uses a convention for their thumbnail images that we can take advantage of here.
// Given the video ID we can construct the URL of the video thumbnail.
// /1.jpg is a 90px tall image that we can use as a "blur-up" placeholder
function getLowResThumbnail(videoId) {
    return `https://i.ytimg.com/vi/${videoId}/1.jpg`;
}

// Similar to above we can construct a higher res thumbnail URL that should be
// 640 x 480 pixels
function getStandardResThumbnail(videoId) {
    return `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`;
}

function PreviewThumbnail({ videoId, title, onClick, ...otherProps }) {
    const classes = useStyles({ classes: otherProps.classes });

    return (
        <div
            role="button"
            tabIndex={0}
            onClick={onClick}
            onKeyDown={(e) => e.key === 'Enter' && onClick()}
            className={classes.root}
        >
            <Img
                alt={title}
                title={title}
                fixed={{
                    base64: getLowResThumbnail(videoId),
                    ...makeFixed(getStandardResThumbnail(videoId), 480, 640),
                }}
                className={classes.previewImage}
                style={{ width: '100%', height: '100%' }}
            />
            <button type="button" className={classes.playButton}>
                <svg
                    height="100%"
                    width="100%"
                    version="1.1"
                    viewBox="0 0 68 48"
                >
                    <path
                        className={classes.playButtonBackgroundSvg}
                        d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                    />
                    <path d="M 45,24 27,14 27,34" fill="#fff" />
                </svg>
            </button>
        </div>
    );
}

PreviewThumbnail.propTypes = {
    videoId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string,
};

PreviewThumbnail.defaultProps = {
    title: '',
};

export default PreviewThumbnail;
