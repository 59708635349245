module.exports = {
    pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
    title: 'Pro.com', // Navigation and Site Title
    titleAlt: 'Pro.com', // Title for JSONLD
    description: 'Improving lives by improving homes',
    headline: 'Improving lives by improving homes', // Headline for schema.org JSONLD
    url: 'https://pro.com', // Domain of your site. No trailing slash!
    siteLanguage: 'en', // Language Tag on <html> element
    logo: '/logos/pro-logo.svg', // Used for SEO
    ogLanguage: 'en_US', // Facebook Language

    // JSONLD / Manifest
    favicon: 'src/images/favicon.png', // Used for manifest favicon generation
    shortName: 'Pro.com', // shortname for manifest. MUST be shorter than 12 characters
    author: 'Pro.com', // Author for schemaORGJSONLD
    themeColor: '#3D63AE',
    backgroundColor: '#EBEDF2',

    twitter: '@getyourpro', // Twitter Username
    facebook: 'GetPro', // Facebook Site Name

    skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature

    additionalSitemapPages: [{ path: '/blog/', priority: 0.6 }],
};
