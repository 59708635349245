import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Dialog, LinearProgress, useMediaQuery } from '@material-ui/core';

import ContactInfo from './ContactInfo';
import ZipCode from './ZipCode';
import DialogHeader from '../Dialog/DialogHeader';
import DialogFooter from '../Dialog/DialogFooter';
import DialogPage from '../Dialog/DialogPage';
import BadZipCode from '../Dialog/BadZipCode';

import {
    isValidZipCode,
    createServiceRequest,
    isValidRequest,
} from '../../../services/requestQuote';
import { RichText } from '../prismic';
import { parseRequestAQuoteData } from '../../../util/parse';
import { track, identify } from '../../../services/segment';
import { withPreviewData } from '../../../util/preview';

export const PAGE = Object.freeze({
    ZIP_CODE: 0,
    CONTACT_INFO: 1,
    COMPLETE: 2,
    BAD_ZIP_CODE: 3,
});

const PAGE_NAME_LOOKUP = {};
Object.keys(PAGE).forEach((key) => {
    PAGE_NAME_LOOKUP[PAGE[key]] = key;
});

const useStyles = makeStyles((theme) => ({
    root: {},
    dialogPaper: {
        overflowY: 'visible',
    },
    dialogPaperFullScreen: {
        overflowY: 'auto',
    },
    headerOnlyDialog: {
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            justifyContent: 'flex-start',
        },
    },
    headerOnlyDialogRichText: {
        '& p': {
            marginTop: '1rem',
        },
    },
}));

function DialogImpl({ startPage, isOpen, setIsOpen, config, ...otherProps }) {
    const classes = useStyles({ classes: otherProps.classes });
    const theme = useTheme();

    // Page nav and loading state
    const [page, setPageState] = useState(startPage);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();

    // User content state
    const [address, setAddress] = useState({});
    const [contactInfo, setContactInfo] = useState({});
    const [notes, setNotes] = useState();
    const [projectType, setProjectType] = useState();
    const { zipCode } = address;

    function getTrackingIdentifier() {
        return `QF${page + 1}_${PAGE_NAME_LOOKUP[page]}`;
    }

    useEffect(() => {
        if (isOpen) {
            track(getTrackingIdentifier(), {
                zipCode,
                emailAddress: contactInfo.emailAddress,
            });
        }
    }, [page, isOpen]);

    function setPage(p) {
        setError(undefined);
        setPageState(p);
    }

    function handleUpdateZipCode(value) {
        setAddress({ ...address, zipCode: value });
    }

    const handleClose = () => {
        setIsOpen(false);
    };

    const reset = () => {
        setPage(PAGE.ZIP_CODE);
        setIsLoading(false);
    };

    const handleUpdateContactInfo = (key, value) => {
        setContactInfo({ ...contactInfo, [key]: value });
    };

    const handleSubmitServiceRequest = async () => {
        try {
            setIsLoading(true);
            setError(undefined);

            track(`${getTrackingIdentifier()}_SUBMITTED`, { zipCode });

            identify(undefined, {
                email: contactInfo.emailAddress,
                phone: contactInfo.phoneNumber,
                firstName: contactInfo.firstName,
                lastName: contactInfo.lastName,
                address: {
                    street: address.street,
                    city: address.city,
                    state: address.state,
                    postalCode: zipCode,
                },
            });

            await createServiceRequest({
                customer: contactInfo,
                serviceAddress: address,
                projectType,
                notes,
            });

            setPage(PAGE.COMPLETE);
        } catch (err) {
            setError(
                'Uh oh! There was an error submitting your request. Please try again or contact support@pro.com if the problem persists.'
            );
            track(`${getTrackingIdentifier()}_ERROR`, { error: err.message });
        } finally {
            setIsLoading(false);
        }
    };

    const handleZipCodeValidation = async () => {
        try {
            setIsLoading(true);
            setError(undefined);
            track(`${getTrackingIdentifier()}_SUBMITTED`, { zipCode });

            const isServiced = await isValidZipCode(zipCode);
            if (isServiced) {
                setPage(PAGE.CONTACT_INFO);
            } else {
                setPage(PAGE.BAD_ZIP_CODE);
            }
        } catch (err) {
            setError(
                'Uh oh! There was an error confirming your ZIP. Please try again or contact support@pro.com if the problem persists.'
            );
            track(`${getTrackingIdentifier()}_ERROR`, { error: err.message });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            onExited={reset}
            className={classes.root}
            classes={{
                paper: classes.dialogPaper,
                paperFullScreen: classes.dialogPaperFullScreen,
            }}
            maxWidth="md"
            fullScreen={useMediaQuery(theme.breakpoints.down('sm'))}
            scroll="body"
        >
            {page === PAGE.ZIP_CODE && (
                <DialogPage
                    title={config.zipCode.title}
                    headerText={<RichText html={config.zipCode.headerHtml} />}
                    onClose={handleClose}
                    content={
                        <ZipCode
                            zipCode={zipCode}
                            onUpdateZipCode={handleUpdateZipCode}
                            onEnter={handleZipCodeValidation}
                        />
                    }
                    footer={
                        <DialogFooter
                            onNext={handleZipCodeValidation}
                            nextDisabled={isLoading || !zipCode}
                            nextLabel={config.zipCode.ctaButtonText}
                            pageNumber={1}
                            totalPages={2}
                            error={error}
                        />
                    }
                />
            )}
            {page === PAGE.CONTACT_INFO && (
                <DialogPage
                    title={config.contactInfo.title}
                    headerText={
                        <RichText html={config.contactInfo.headerHtml} />
                    }
                    onClose={handleClose}
                    content={
                        <ContactInfo
                            contactInfo={contactInfo}
                            onUpdateContactInfo={handleUpdateContactInfo}
                            notes={notes}
                            onUpdateNotes={setNotes}
                            projectType={projectType}
                            onUpdateProjectType={setProjectType}
                            projectTypes={config.contactInfo.projectTypes}
                            labels={{
                                contactInfoSubheader:
                                    config.contactInfo.contactInfoSubheader,
                                projectDetailsSubheader:
                                    config.contactInfo.projectDetailsSubheader,
                                projectTypePlaceholder:
                                    config.contactInfo.projectTypePlaceholder,
                                notesPlaceholder:
                                    config.contactInfo.notesPlaceholder,
                            }}
                        />
                    }
                    footer={
                        <DialogFooter
                            onNext={handleSubmitServiceRequest}
                            nextDisabled={
                                isLoading ||
                                !projectType ||
                                !isValidRequest(contactInfo, address)
                            }
                            nextLabel={config.contactInfo.ctaButtonText}
                            onBack={() => setPage(PAGE.ZIP_CODE)}
                            backDisabled={isLoading}
                            pageNumber={2}
                            totalPages={2}
                            error={error}
                            disclaimer={
                                <RichText
                                    html={config.contactInfo.disclaimerHtml}
                                />
                            }
                        />
                    }
                />
            )}
            {page === PAGE.COMPLETE && (
                <DialogHeader
                    title={config.confirmation.title}
                    onClose={handleClose}
                    instructionText={
                        <RichText
                            html={config.confirmation.headerHtml}
                            classes={{ root: classes.headerOnlyDialogRichText }}
                        />
                    }
                    classes={{ root: classes.headerOnlyDialog }}
                />
            )}
            {page === PAGE.BAD_ZIP_CODE && (
                <DialogPage
                    onClose={handleClose}
                    title={config.badZipCode.title}
                    headerText={
                        <RichText html={config.badZipCode.headerHtml} />
                    }
                    content={
                        <BadZipCode
                            zipCode={zipCode}
                            labels={{
                                signUpLabelHtml:
                                    config.badZipCode.signUpLabelHtml,
                                ctaButtonText: config.badZipCode.ctaButtonText,
                                partnerReferenceLabelHtml:
                                    config.badZipCode.partnerReferenceLabelHtml,
                                thankYouMessageLabelHtml:
                                    config.badZipCode.thankYouMessageLabelHtml,
                            }}
                            partnerLinks={config.badZipCode.partnerLinks}
                        />
                    }
                    footer={
                        <DialogFooter onBack={() => setPage(PAGE.ZIP_CODE)} />
                    }
                />
            )}
            {isLoading && <LinearProgress />}
        </Dialog>
    );
}

DialogImpl.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    startPage: PropTypes.oneOf(Object.values(PAGE)),
    config: PropTypes.object.isRequired,
};

DialogImpl.defaultProps = {
    startPage: PAGE.ZIP_CODE,
};

const query = graphql`
    query RequestAQuoteQuery {
        prismicRequestAQuoteModal {
            data {
                zip_title {
                    text
                }
                zip_header_text {
                    html
                }
                zip_cta_button_text {
                    text
                }

                bad_zip_title {
                    text
                }
                bad_zip_header_text {
                    html
                }
                bad_zip_sign_up_label {
                    html
                }
                bad_zip_cta_button_text {
                    text
                }
                bad_zip_partner_reference_label {
                    html
                }
                partner_links {
                    partner_link {
                        url
                    }
                    partner_logo {
                        ...ProPrismicImageMetadata
                    }
                }
                bad_zip_thank_you_message_label {
                    html
                }

                contact_title {
                    text
                }
                contact_header_text {
                    html
                }
                contact_info_subheader {
                    text
                }
                project_details_subheader
                project_type_placeholder
                notes_placeholder
                project_types {
                    project_type_option {
                        text
                    }
                }
                property_info_subheader {
                    text
                }
                disclaimer_text {
                    html
                }
                contact_cta_button_text {
                    text
                }

                confirmation_header_text {
                    html
                }
                confirmation_title {
                    text
                }
            }
        }
    }
`;

function StaticDialog(props) {
    return (
        <StaticQuery
            query={query}
            render={(staticData) => {
                const {
                    prismicRequestAQuoteModal: { data },
                } = withPreviewData(staticData);

                return (
                    <DialogImpl
                        {...props}
                        config={parseRequestAQuoteData(data)}
                    />
                );
            }}
        />
    );
}

export default StaticDialog;
