import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';

import Link from './LinkWrapper';
import ImageCard from './ImageCard';
import Section from './Section';
import SectionHeader from './SectionHeader';
import Caption from './Caption';

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        textTransform: 'uppercase',
    },
    subtitle: {
        textAlign: 'center',
    },
    grid: {
        marginTop: theme.spacing(2),
    },
    captionTitle: {
        textTransform: 'uppercase',
        [theme.breakpoints.up('md')]: {
            fontSize: 18,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        },
    },
    seeMoreButton: {
        marginTop: theme.spacing(2),
    },
}));

function FeaturedProjects(props) {
    const {
        title,
        projects: unfilteredProjects,
        color,
        subtitle,
        seeMore,
        rows,
        ...otherProps
    } = props;

    const classes = useStyles({ classes: otherProps.classes });
    const projects = unfilteredProjects.filter(({ image = {} }) => image.url);

    const hasTitle = !!title;
    const hasProjects = !!projects.length;
    const hasSeeMore = !!(seeMore && seeMore.text && seeMore.url);

    const shouldRender = hasTitle || hasProjects || hasSeeMore;

    if (!shouldRender) return <></>;

    return (
        <Section color={color} className={classes.root}>
            {title && <SectionHeader title={title} />}
            {subtitle && (
                <Caption
                    caption={subtitle}
                    classes={{ root: classes.subtitle }}
                />
            )}
            {hasProjects && (
                <Grid
                    container
                    spacing={4}
                    justify="center"
                    className={classes.grid}
                >
                    {projects.map((project, i) => (
                        <Grid item xs={12} sm={rows} key={`project_${i + 1}`}>
                            <Link to={project.link.url}>
                                <ImageCard
                                    classes={{
                                        root: classes.link,
                                        captionTitle: classes.captionTitle,
                                    }}
                                    image={project.image}
                                    imageWidth={400}
                                    captionTitle={project.title}
                                    caption={project.description}
                                />
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            )}
            {hasSeeMore && (
                <Link to={seeMore.url}>
                    <Button
                        className={classes.seeMoreButton}
                        variant="contained"
                        color="primary"
                    >
                        {seeMore.text}
                    </Button>
                </Link>
            )}
        </Section>
    );
}

FeaturedProjects.propTypes = {
    projects: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            fixedImage: PropTypes.object,
            link: PropTypes.object,
        })
    ).isRequired,
    title: PropTypes.string,
    color: PropTypes.string,
    subtitle: PropTypes.string,
    seeMore: PropTypes.shape({
        url: PropTypes.string,
        text: PropTypes.string,
    }),
    rows: PropTypes.number,
};

FeaturedProjects.defaultProps = {
    subtitle: '',
    title: '',
    color: 'secondary',
    seeMore: undefined,
    rows: 4,
};

export default FeaturedProjects;
