import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { RichText } from './prismic';

const useStyles = makeStyles({
    root: {
        textAlign: 'left',
        '& p': {
            margin: 0,
        },
    },
});

function Caption({ caption, variant, ...otherProps }) {
    const classes = useStyles({ classes: otherProps.classes });
    return (
        /**
         * It's required that we don't use a <p> tag for the typography
         * component here due to https://github.com/gatsbyjs/gatsby/issues/11108
         */
        <Typography className={classes.root} variant={variant} component="div">
            <RichText html={caption} />
        </Typography>
    );
}

Caption.propTypes = {
    caption: PropTypes.string.isRequired,
    variant: PropTypes.string,
};

Caption.defaultProps = {
    variant: 'body1',
};

export default Caption;
