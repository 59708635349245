import { graphql } from 'gatsby';

import { makeFixed, makeFluid } from '../util/gatsbyImage';

export default class PrismicImage {
    constructor({ alt, url, dimensions, localFile }) {
        Object.assign(this, { alt, url, dimensions });

        if (localFile && localFile.childImageSharp) {
            const { fixed, fluid } = localFile.childImageSharp;
            if (fixed && fixed.base64) {
                this.base64 = fixed.base64;

                // We'll differentiate between a real fixed image generated
                // and the fixed image in our ProPrismicImageMetadata fragment
                // that's just used for the base64 image by looking for
                // a srcSet. If srcSet is present, it's a real fixed image
                // and we'll save that off.
                if (fixed.srcSet) {
                    this.preprocessedFixed = fixed;
                }
            }
            if (fluid) {
                this.preprocessedFluid = fluid;
            }
        }
    }

    fluid(options) {
        // If a preprocessed fluid image is provided, we'll blindly use that
        // assuming it matches the desired options provided.
        if (this.preprocessedFluid) {
            return this.preprocessedFluid;
        }

        const { maxWidth } = options;
        const { height, width } = this.dimensions || {};
        const { base64 } = this;

        return makeFluid(this.url, height, width, { maxWidth, base64 });
    }

    fixed(options) {
        // If a preprocessed fixed image is provided, we'll blindly use that
        // assuming it matches the desired options provided.
        if (this.preprocessedFixed) {
            return this.preprocessedFixed;
        }

        const { width: desiredWidth, height: desiredHeight } = options;
        const { height, width } = this.dimensions || {};
        const { base64 } = this;

        const f = makeFixed(this.url, height, width, {
            desiredWidth,
            desiredHeight,
            base64,
        });

        return f;
    }
}

/**
 * NOTE: I (Troy) would like to use the following query to generate base64 images, but can't
 * due to https://github.com/gatsbyjs/gatsby/issues/19220
 *
 * localFile {
 *     childImageSharp {
 *         resize(width: 20, base64: true) {
 *             src
 *         }
 *     }
 * }
 *
 * In the meantime, we'll use `fixed`, which generates 3 versions of the image
 * at 1x, 1.5x, and 2x the requested resolution. `resize` would only generate
 * 1 version of the image.
 */
export const ProPrismicImageMetadata = graphql`
    fragment ProPrismicImageMetadata on PrismicImageType {
        url
        alt
        dimensions {
            height
            width
        }
        localFile {
            childImageSharp {
                fixed(width: 20) {
                    width
                    base64
                }
            }
        }
    }
`;

// eslint-disable-next-line camelcase
export const ProPrismicImageMetadata_NoBase64 = graphql`
    fragment ProPrismicImageMetadata_NoBase64 on PrismicImageType {
        url
        alt
        dimensions {
            height
            width
        }
    }
`;
