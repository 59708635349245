import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import Section from './Section';
import EmbeddedYoutubeVideo from './EmbeddedYoutubeVideo';
import MediaCard from './MediaCard';
import SectionHeader from './SectionHeader';

const useStyles = makeStyles((theme) => ({
    root: {},
    videoCard: {
        height: '25vw',
        maxHeight: 300,
        [theme.breakpoints.down('xs')]: {
            minHeight: 180,
        },
        [theme.breakpoints.up('lg')]: {
            maxHeight: 340,
        },
    },
    videoParent: {
        marginTop: theme.spacing(6),
        '& p': {
            marginTop: theme.spacing(1),
        },
    },
}));

function VideoTestimonials({ title, videos, ...otherProps }) {
    const classes = useStyles({ classes: otherProps.classes });

    return (
        <Section classes={{ root: classes.root }}>
            {title && <SectionHeader title={title} />}
            {videos.length > 0 && (
                <Grid
                    container
                    spacing={4}
                    className={classes.videoParent}
                    justify="center"
                >
                    {videos.map((video, i) => (
                        <Grid item xs={12} sm={6} key={`video_${i + 1}`}>
                            <MediaCard
                                caption={video.caption.html}
                                classes={{
                                    imageContainer: classes.videoCard,
                                }}
                            >
                                <EmbeddedYoutubeVideo
                                    link={video.videoId.text}
                                    title={video.caption.text}
                                />
                            </MediaCard>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Section>
    );
}

VideoTestimonials.propTypes = {
    title: PropTypes.string,
    videos: PropTypes.arrayOf(
        PropTypes.shape({
            videoId: PropTypes.shape({
                text: PropTypes.string.isRequired,
            }),
            caption: PropTypes.shape({
                html: PropTypes.string.isRequired,
                text: PropTypes.string.isRequired,
            }),
        })
    ),
};

VideoTestimonials.defaultProps = {
    title: undefined,
    videos: [],
};

export default VideoTestimonials;
