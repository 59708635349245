import React, { forwardRef } from 'react';
import { useTheme } from '@material-ui/core/styles';

import Link from './Link';

const LinkWrapper = forwardRef((props, ref) => {
    const theme = useTheme();
    const linkStyles = {
        textDecoration: 'none',
        color: theme.palette.text.primary,
    };

    return <Link style={linkStyles} ref={ref} {...props} />;
});

export default LinkWrapper;
