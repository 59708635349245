import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '1rem',
        height: '1rem',
        borderRadius: '50%',
        flex: '0 1 auto',
        margin: theme.spacing(1),
        backgroundColor: ({ selected }) =>
            theme.palette.grey[selected ? 700 : 100],
        cursor: 'pointer',
    },
    link: {
        display: 'block',
        width: '100%',
        height: '100%',
    },
    screenReaderText: {
        display: 'none',
    },
}));

function ProgressDot(props) {
    const { selected, onSelect, ...otherProps } = props;
    const classes = useStyles({ selected, classes: otherProps.classes });

    return (
        <li className={classes.root}>
            <a
                role="button"
                tabIndex={-1}
                className={classes.link}
                onClick={onSelect}
                onKeyDown={(event) =>
                    event.key !== 'Tab' ? onSelect() : undefined
                }
                aria-label="Skip to item"
            >
                <span className={classes.screenReaderText}>Skip to item</span>
            </a>
        </li>
    );
}

ProgressDot.propTypes = {
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
};

ProgressDot.defaultProps = {
    selected: false,
    onSelect: () => {},
};

export default ProgressDot;
