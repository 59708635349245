import {
    calculateImgixFixedSrc,
    calculateImgixFixedSrcSet,
    calculateImgixFluidSrc,
    calculateImgixFluidSrcSet,
    isImgixUrl,
} from './imgix';

/**
 * Given a height and width, return the dimensions needed
 * for rendering a fixed or fluid gatsby image, including
 * the aspect ratio.
 *
 * @param {*} height
 * @param {*} width
 */
export function calculateDimensions(
    actualHeight,
    actualWidth,
    desiredHeight,
    desiredWidth
) {
    let width;
    if (desiredWidth) {
        width = desiredWidth;
    } else if (desiredHeight && actualWidth && actualHeight) {
        width = Math.round(actualWidth * (desiredHeight / actualHeight));
    } else {
        width = actualWidth;
    }

    let height;
    if (desiredHeight) {
        height = desiredHeight;
    } else if (desiredWidth && actualWidth && actualHeight) {
        height = Math.round(actualHeight * (desiredWidth / actualWidth));
    } else {
        height = actualHeight;
    }

    return { height, width };
}

export function makeFluid(url, height, width, options) {
    const { maxWidth, base64: providedBase64 } = options || {};
    let srcSet;
    let src;
    let base64 = providedBase64;

    if (isImgixUrl(url)) {
        const desiredWidth = maxWidth || width;

        if (!base64) {
            base64 = calculateImgixFluidSrc(url, 20);
        }

        src = calculateImgixFluidSrc(url, desiredWidth);
        srcSet = calculateImgixFluidSrcSet(url, desiredWidth);
    } else {
        src = url;
        srcSet = url;
    }

    return {
        base64,
        src,
        srcSet,
        sizes: '100vw',
        aspectRatio: width && height ? width / height : undefined,
    };
}

export function makeFixed(url, height, width, options) {
    const { desiredWidth, desiredHeight, base64: providedBase64 } =
        options || {};

    let srcSet;
    let src;
    let base64 = providedBase64;

    if (isImgixUrl(url)) {
        if (!base64) {
            base64 = calculateImgixFixedSrc(url, 20, undefined, 1);
        }
        src = calculateImgixFixedSrc(url, desiredWidth, desiredHeight, 1);
        srcSet = calculateImgixFixedSrcSet(url, {
            width: desiredWidth,
            height: desiredHeight,
        });
    } else {
        src = url;
        srcSet = url;
    }

    return {
        base64,
        src,
        srcSet,
        ...calculateDimensions(height, width, desiredHeight, desiredWidth),
    };
}
