import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, Container } from '@material-ui/core';
import { StaticQuery, graphql } from 'gatsby';
import AppBarBottom from '@serviceslabs/material-ui-pro/AppBarBottom';
import { grey, blue } from '@serviceslabs/material-ui-pro/colors';

import {
    title as prismicTitle,
    link as prismicLink,
} from '../../shapes/prismic';
import Section from './Section';
import proLogo from '../../images/pro-logo-white.svg';
import { track } from '../../services/segment';
import { withPreviewData } from '../../util/preview';
import PrismicLink from './Link/PrismicLink';

const useStyle = makeStyles((theme) => ({
    content: {
        margin: `0 auto ${theme.spacing(2)}px auto`,
        textTransform: 'uppercase',
        fontWeight: 600,
    },
    subHeader: {
        margin: `0 auto ${theme.spacing(2)}px auto`,
        textDecoration: 'underline',
        fontWeight: 500,
    },
    link: {
        color: theme.palette.primary.contrastText,
    },
    logo: {
        width: '100%',
        maxWidth: 126,
    },
    icon: {
        height: 35,
        margin: theme.spacing(1),
    },
    iconLink: {
        textDecoration: 'none',
    },
    linkContainer: {
        margin: theme.spacing(2, 0),
    },
    license: {
        margin: theme.spacing(2, 0),
    },
    section: { textAlign: 'left' },
    sectionContainer: {
        padding: 0,
    },
    appBarBackground: {
        backgroundColor: grey[700],
    },
    button: {
        backgroundColor: theme.palette.common.white,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        whiteSpace: 'nowrap',
    },
    telNumber: {
        marginTop: theme.spacing(2),
        whiteSpace: 'nowrap',
    },
    callLink: {
        textDecoration: 'none',
        color: 'inherit',
    },
}));

export const Header = ({ classes, title }) => (
    <Typography variant="subtitle1" color="inherit" className={classes.content}>
        {title}
    </Typography>
);

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
};

export const SubHeader = ({ classes, title }) => (
    <Typography
        variant="subtitle1"
        color="inherit"
        className={classes.subHeader}
    >
        {title}
    </Typography>
);

SubHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
};

/**
 * This functor returns an onClick function that will track
 * the click in segment. It will only return the onClick function
 * if the link was a "tel:" href. If it's anything else, it returns
 * `undefined`, which will do nothing on click.
 *
 * @param {function} href
 */
const trackClickIfTelLink = (href) => {
    if (href && href.startsWith('tel')) {
        return () =>
            track('Call us link clicked', {
                context: 'footer',
            });
    }
    return undefined;
};

export const LinkList = ({ classes, links }) =>
    links.map(({ link_text: linkText, link }) =>
        linkText && link && link.url ? (
            <div key={linkText.text} className={classes.linkContainer}>
                <PrismicLink
                    onClick={trackClickIfTelLink(link.url)}
                    link={link}
                    className={classes.link}
                >
                    {linkText.text}
                </PrismicLink>
            </div>
        ) : undefined
    );

LinkList.propTypes = {
    classes: PropTypes.object.isRequired,
    links: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const useSecondaryFooterStyles = makeStyles((theme) => ({
    secondaryFooter: {
        backgroundColor: grey[800],
        color: blue[200],
        textAlign: 'center',
        padding: theme.spacing(2),
    },
    secondaryFooterLinkContainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            marginTop: theme.spacing(1),
        },
    },
    secondaryFooterLink: {
        color: theme.palette.common.white,
        textDecoration: 'none',
        marginLeft: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
        borderLeft: `1px solid ${blue[200]}`,
        [theme.breakpoints.down('sm')]: {
            '&:first-child': {
                marginLeft: 0,
                paddingLeft: 0,
                borderLeft: 'none',
            },
        },
    },
}));

export const SecondaryFooter = ({ copyrightText, secondaryFooterLinks }) => {
    const classes = useSecondaryFooterStyles();

    return (
        <div className={classes.secondaryFooter}>
            <Container maxWidth="lg">
                <Typography variant="body2" component="span">
                    {copyrightText}
                </Typography>
                <span className={classes.secondaryFooterLinkContainer}>
                    {(secondaryFooterLinks || []).map(
                        ({ link, link_text: linkText }) => (
                            <PrismicLink
                                key={linkText}
                                link={link}
                                className={classes.secondaryFooterLink}
                            >
                                {linkText}
                            </PrismicLink>
                        )
                    )}
                </span>
            </Container>
        </div>
    );
};

SecondaryFooter.propTypes = {
    copyrightText: PropTypes.string,
    secondaryFooterLinks: PropTypes.arrayOf(
        PropTypes.shape({
            link: prismicLink,
            link_text: PropTypes.string,
        })
    ),
};

SecondaryFooter.defaultProps = {
    copyrightText: '',
    secondaryFooterLinks: [],
};

export const FooterImpl = ({
    className,
    data: {
        prismicFooter: {
            data: {
                licensesHeader,
                licensesLinks,
                contact_us_text: contactUsText,
                contact_phone_number: contactPhoneNumber,
                copyright_text: copyrightText,
                secondary_footer_links: secondaryFooterLinks,
            },
        },
    },
}) => {
    const classes = useStyle();
    return (
        <AppBarBottom
            className={className}
            classes={{ colorPrimary: classes.appBarBackground }}
            position="static"
        >
            <Section
                classes={{
                    root: classes.section,
                    container: classes.sectionContainer,
                }}
            >
                <Grid container spacing={4}>
                    {/* First row of the footer - log, link farm */}
                    <Grid item xs={12} sm={2} md={3}>
                        <img
                            className={classes.logo}
                            src={proLogo}
                            alt="Pro.com logo"
                        />
                    </Grid>

                    <Grid item xs={12} sm={7} md={6}>
                        <Header classes={classes} title={licensesHeader.text} />
                        {licensesLinks.map((item) => (
                            <Typography
                                key={item.text.text}
                                variant="body2"
                                color="inherit"
                                className={classes.license}
                            >
                                {item.text.text}
                            </Typography>
                        ))}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Header classes={classes} title={contactUsText} />
                        <a
                            href={`tel:${contactPhoneNumber}`}
                            className={classes.callLink}
                            onClick={trackClickIfTelLink(
                                `tel:${contactPhoneNumber}`
                            )}
                        >
                            <Typography
                                variant="body1"
                                className={classes.telNumber}
                            >
                                {contactPhoneNumber}
                            </Typography>
                        </a>
                        <a
                            href="mailto:support@pro.com"
                            className={classes.callLink}
                        >
                            <Typography
                                variant="body1"
                                className={classes.telNumber}
                            >
                                support@pro.com
                            </Typography>
                        </a>
                    </Grid>
                </Grid>
            </Section>
            <SecondaryFooter
                copyrightText={copyrightText}
                secondaryFooterLinks={secondaryFooterLinks}
            />
        </AppBarBottom>
    );
};

FooterImpl.propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({
        prismicFooter: PropTypes.shape({
            data: PropTypes.shape({
                licensesHeader: prismicTitle,
                licensesLinks: PropTypes.arrayOf(
                    PropTypes.shape({
                        text: prismicTitle,
                    })
                ),
                contact_us_text: PropTypes.string,
                contact_phone_number: PropTypes.string,
                copyright_text: PropTypes.string,
                secondary_footer_links: PropTypes.arrayOf(
                    PropTypes.shape({
                        link: prismicLink,
                        link_text: PropTypes.string,
                    })
                ),
            }),
        }),
    }).isRequired,
};

FooterImpl.defaultProps = {
    className: undefined,
};

export default () => (
    <StaticQuery
        query={graphql`
            query FooterQuery {
                prismicFooter {
                    data {
                        licensesHeader {
                            text
                        }
                        licensesLinks {
                            text {
                                text
                            }
                        }
                        contact_us_text
                        contact_phone_number
                        copyright_text
                        secondary_footer_links {
                            link {
                                url
                                isBroken
                                link_type
                                target
                            }
                            link_text
                        }
                    }
                }
            }
        `}
        render={(data) => <FooterImpl data={withPreviewData(data)} />}
    />
);
