import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ typography }) => ({
    root: {
        '& p': {
            marginTop: 0,
            marginBottom: 0,
        },
        '& h1': typography.h1,
        '& h2': typography.h2,
        '& h3': typography.h3,
        '& h4': typography.h4,
        '& h5': typography.h5,
        '& h6': typography.h6,
    },
}));

export default function RichText({ html, ...otherProps }) {
    const classes = useStyles({ classes: otherProps.classes });
    return (
        <div
            className={classes.root}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: `<div>${html}</div>` }}
        />
    );
}

RichText.propTypes = {
    html: PropTypes.string.isRequired,
};
