import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image/withIEPolyfill';
import PrismicImage from '../../../models/prismicImage';

export default function FluidImage({ image, maxWidth, ...props }) {
    return (
        <Img
            {...props}
            alt={image.alt}
            title={image.alt}
            fluid={image.fluid({ maxWidth })}
        />
    );
}

FluidImage.propTypes = {
    image: PropTypes.instanceOf(PrismicImage).isRequired,
    maxWidth: PropTypes.number.isRequired,
};

FluidImage.defaultProps = {};
