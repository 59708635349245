import React from 'react';
import PropTypes from 'prop-types';
import '@reach/skip-nav/styles.css';
import { makeStyles } from '@material-ui/styles';
import { Helmet } from 'react-helmet';

import website from '../../../config/website';

import SEO from '../SEO';
import SkipNavLink from './SkipNavLink';
import Footer from './Footer';
import NavBar from './NavBar';
import * as fonts from './Fonts';

const fontFaces = {
    100: 'GibsonLight',
    200: 'GibsonLight',
    300: 'GibsonLight',
    400: 'GibsonLight',
    500: 'GibsonRegular',
    600: 'GibsonSemiBold',
    700: 'GibsonSemiBold',
    800: 'GibsonSemiBold',
    900: 'GibsonSemiBold',
};

const fontStyles = Object.keys(fontFaces).reduce((styles, weight) => {
    return `${styles}
    @font-face {
        font-family: 'Gibson';
        src: url(${fonts[`${fontFaces[weight]}EOT`]});
        src: url(${fonts[`${fontFaces[weight]}WOFF`]}) format('woff'),
             url(${fonts[`${fontFaces[weight]}TTF`]}) format('truetype'),
             url(${fonts[`${fontFaces[weight]}SVG`]}) format('svg');
        font-weight: ${weight};
        font-style: normal;
        font-display: swap;
    }`;
}, '');

const useStyles = makeStyles(({ palette }) => {
    const footerHeight = '4rem';
    const defaultMaxW = '50rem';

    const safeW =
        'calc(' +
        '100% - env(safe-area-inset-right) ' +
        '- env(safe-area-inset-left))';

    const safeFooterPadding = 'env(safe-area-inset-bottom)';
    const safeFooterH = `calc(${footerHeight} + ${safeFooterPadding})`;

    return {
        '@global': {
            'html, body, #___gatsby, #gatsby-focus-wrapper': {
                backgroundColor: palette.background.default,
            },
            '#gatsby-focus-wrapper': {
                display: 'flex',
                flexDirection: 'column',
            },
        },
        root: {
            minHeight: '100vh',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        content: {
            flexGrow: 1,
            justifyContent: 'center',
            '@supports not (min-height: calc(100% - 1em))': {
                paddingBottom: footerHeight,
            },
        },
        footer: {
            minHeight: footerHeight,
            '@supports (min-height: calc(100% - 1em))': {
                minHeight: safeFooterH,
                paddingBottom: safeFooterPadding,
            },
        },
        constrainedWidth: {
            width: '100%',
            maxWidth: defaultMaxW,
            '@supports (max-width: max(1em, calc(100% - 1em)))': {
                maxWidth: `min(${defaultMaxW}, ${safeW})`,
            },
        },
    };
});

function Layout({
    children,
    customMetaData: { title, description, jsonLD },
    navBarProps,
    ...otherProps
}) {
    const classes = useStyles({ classes: otherProps.classes });
    return (
        <>
            <SEO title={title} description={description} jsonLD={jsonLD} />
            <Helmet>
                <style>{fontStyles}</style>
            </Helmet>
            <div className={classes.root}>
                <div className={classes.content}>
                    <SkipNavLink text="Skip to main content" />
                    <NavBar {...navBarProps} />
                    <main id={website.skipNavId}>{children}</main>
                </div>
                <Footer className={classes.footer} />
            </div>
        </>
    );
}

Layout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
    customMetaData: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        jsonLD: PropTypes.string,
    }),
    navBarProps: PropTypes.object,
};

Layout.defaultProps = {
    customMetaData: {},
    navBarProps: {},
};

export default Layout;
