import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid } from '@material-ui/core';

import { FluidImage } from './Image';
import Caption from './Caption';
import { StaticRequestAQuoteButton } from './RequestQuote';
import Section from './Section';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 0,
        paddingBottom: 0,
        [theme.breakpoints.down('lg')]: {
            padding: 0,
        },
    },
    sectionContainer: {
        [theme.breakpoints.down('lg')]: {
            padding: 0,
        },
    },
    sectionHeader: {
        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
        },
    },
    button: {
        [theme.breakpoints.up('md')]: {
            left: 0,
        },
        flexGrow: 0,
        marginTop: theme.spacing(3),
    },
    buttonContainer: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
        [theme.breakpoints.up('md')]: {
            left: 0,
            display: 'flex',
            flexDirection: 'row',
        },
    },
    caption: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    textContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(20),
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            margin: theme.spacing(6),
        },
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(4),
        },
    },
}));

const SectionText = ({ title, body, mapImage }) => {
    const classes = useStyles();

    return (
        <Section
            color="secondary"
            classes={{
                root: classes.root,
                container: classes.sectionContainer,
            }}
        >
            <Grid container spacing={0} className={classes.root}>
                <Grid item xs={12} md={6}>
                    <FluidImage image={mapImage} maxWidth={960} />
                </Grid>
                <Grid item xs={12} md={4} className={classes.textContainer}>
                    <Typography variant="h4" className={classes.sectionHeader}>
                        {title}
                    </Typography>
                    <Caption
                        caption={body}
                        classes={{ root: classes.caption }}
                    />
                    <div className={classes.buttonContainer}>
                        <StaticRequestAQuoteButton
                            classes={{ root: classes.button }}
                            contextDescription="where-we-work"
                            size="large"
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={2} />
            </Grid>
        </Section>
    );
};

SectionText.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    mapImage: PropTypes.object.isRequired,
};

export default SectionText;
