import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    TextField,
    Grid,
    Typography,
    Select,
    FormControl,
    FilledInput,
    MenuItem,
    InputLabel,
} from '@material-ui/core';

import PhoneNumberTextField from '../PhoneNumberTextField';

const useTextFieldStyles = makeStyles((theme) => ({
    root: {
        marginTop: 0,
        marginBottom: 0,
    },
    textField: {},
    heading: {
        fontWeight: 500,
        color: theme.palette.primary.dark,
    },
    lightHeading: {
        color: theme.palette.primary.dark,
    },
}));

function ContactInfo(props) {
    const {
        contactInfo,
        onUpdateContactInfo,
        notes,
        onUpdateNotes,
        projectType,
        onUpdateProjectType,
        projectTypes,
        labels: {
            contactInfoSubheader,
            projectDetailsSubheader,
            projectTypePlaceholder,
            notesPlaceholder,
        },
        ...otherProps
    } = props;
    const classes = useTextFieldStyles({ classes: otherProps.classes });

    return (
        <Grid container spacing={6} className={classes.root}>
            <Grid item xs={12} md={6}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography className={classes.heading} variant="body2">
                            {contactInfoSubheader}
                        </Typography>
                        <TextField
                            autoFocus
                            key="firstName"
                            id="firstNameInput"
                            label="First Name"
                            value={contactInfo.firstName || ''}
                            onChange={(event) =>
                                onUpdateContactInfo(
                                    'firstName',
                                    event.target.value
                                )
                            }
                            className={classes.textField}
                            margin="dense"
                            variant="filled"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            key="lastName"
                            id="lastNameInput"
                            label="Last Name"
                            value={contactInfo.lastName || ''}
                            onChange={(event) =>
                                onUpdateContactInfo(
                                    'lastName',
                                    event.target.value
                                )
                            }
                            className={classes.textField}
                            margin="dense"
                            variant="filled"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            key="emailAddress"
                            id="emailAddressInput"
                            label="Email Address"
                            type="email"
                            value={contactInfo.emailAddress || ''}
                            onChange={(event) =>
                                onUpdateContactInfo(
                                    'emailAddress',
                                    event.target.value
                                )
                            }
                            className={classes.textField}
                            margin="dense"
                            variant="filled"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PhoneNumberTextField
                            value={contactInfo.phoneNumber || ''}
                            onChange={(value) =>
                                onUpdateContactInfo('phoneNumber', value)
                            }
                            limitInputDigits
                            key="phoneNumber"
                            id="phoneNumberInput"
                            label="Phone Number"
                            margin="dense"
                            variant="filled"
                            fullWidth
                            required
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography className={classes.heading} variant="body2">
                            {projectDetailsSubheader}
                        </Typography>
                        <FormControl fullWidth variant="filled" margin="dense">
                            <InputLabel>{projectTypePlaceholder} *</InputLabel>
                            <Select
                                key="projectType"
                                value={projectType || ''}
                                onChange={(event) =>
                                    onUpdateProjectType(event.target.value)
                                }
                                input={<FilledInput name="projectType" />}
                            >
                                {projectTypes.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            key="notes"
                            id="notesInput"
                            label={notesPlaceholder}
                            value={notes || ''}
                            onChange={(event) =>
                                onUpdateNotes(event.target.value)
                            }
                            margin="dense"
                            variant="filled"
                            multiline
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

ContactInfo.propTypes = {
    classes: PropTypes.object,
    onUpdateContactInfo: PropTypes.func.isRequired,
    contactInfo: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        emailAddress: PropTypes.string,
        phoneNumber: PropTypes.string,
    }).isRequired,
    notes: PropTypes.string,
    onUpdateNotes: PropTypes.func.isRequired,
    projectType: PropTypes.string,
    onUpdateProjectType: PropTypes.func.isRequired,
    projectTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    labels: PropTypes.shape({
        contactInfoSubheader: PropTypes.string,
        projectDetailsSubheader: PropTypes.string,
        projectTypePlaceholder: PropTypes.string,
        notesPlaceholder: PropTypes.string,
    }),
};

ContactInfo.defaultProps = {
    classes: undefined,
    notes: '',
    projectType: undefined,
    labels: {
        contactInfoSubheader: 'Contact Information',
        projectDetailsSubheader: 'Project Details',
        projectTypePlaceholder: 'Project Type',
        notesPlaceholder: 'Tell us more about your project (optional)',
    },
};

export default ContactInfo;
