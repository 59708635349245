import { useState, useEffect } from 'react';
import getWindow from './window';

export default function useScroll() {
    const window = getWindow();
    const [scroll, setScroll] = useState(window.scrollY);

    useEffect(() => {
        const handler = () => setScroll(window.scrollY);

        window.addEventListener('scroll', handler);
        return () => window.removeEventListener('scroll', handler);
    }, []);

    return scroll;
}
