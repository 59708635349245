import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {},
}));

const SectionHeader = (props) => {
    const { title, ...otherProps } = props;
    const classes = useStyles({ classes: otherProps.classes });

    return (
        <Typography variant="h3" className={classes.root}>
            {title}
        </Typography>
    );
};

SectionHeader.propTypes = {
    title: PropTypes.string.isRequired,
    classes: PropTypes.object,
};

SectionHeader.defaultProps = {
    classes: undefined,
};

export default SectionHeader;
