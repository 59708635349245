import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/styles';

import proLogo from '../../../images/pro-logo-white.svg';
import StaticSigninButton from '../SignIn/StaticSigninButton';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    logoContainer: {
        flexGrow: 0,
        width: 56,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    logo: {
        display: 'inline-block',
    },
    ctaContainer: {
        display: 'flex',
        alignItems: 'center',
    },
}));

function MobileMenu({ ...otherProps }) {
    const classes = useStyles({ classes: otherProps.classes });

    return (
        <div className={classes.root}>
            <div className={classes.logoContainer}>
                <Link to="/" className={classes.logo}>
                    <img src={proLogo} alt="Pro.com logo" />
                </Link>
            </div>
            <div className={classes.ctaContainer}>
                <StaticSigninButton />
            </div>
        </div>
    );
}

MobileMenu.propTypes = {
    classes: PropTypes.object,
};

MobileMenu.defaultProps = {
    classes: undefined,
};

export default MobileMenu;
