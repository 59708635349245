import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import Dialog from './Dialog';
import { track } from '../../../services/segment';

const useStyles = makeStyles(() => ({
    root: {},
    button: {},
    label: {
        whiteSpace: 'nowrap',
    },
}));

function RequestAQuoteButton({
    text,
    contextDescription,
    size,
    ...otherProps
}) {
    const classes = useStyles({ classes: otherProps.classes });

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={classes.root}>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                    setIsOpen(true);
                    track('Request quote button clicked', {
                        context: contextDescription,
                        ctaText: text,
                    });
                }}
                size={size}
                classes={{ label: classes.label, root: classes.button }}
            >
                {text}
            </Button>
            <Dialog isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
    );
}

RequestAQuoteButton.propTypes = {
    text: PropTypes.string,
    contextDescription: PropTypes.string,
    size: PropTypes.string,
};

RequestAQuoteButton.defaultProps = {
    text: 'Request a Quote',
    contextDescription: undefined,
    size: 'medium',
};

export default RequestAQuoteButton;
