import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import RequestAQuoteButton from './RequestAQuoteButton';
import { withPreviewData } from '../../../util/preview';

const query = graphql`
    query RequestAQuoteButton {
        prismicNavBar {
            data {
                cta_button_text {
                    text
                }
            }
        }
    }
`;

function StaticRequestAQuoteButton(props) {
    return (
        <StaticQuery
            query={query}
            render={(staticData) => {
                const {
                    prismicNavBar: {
                        data: { cta_button_text: ctaButtonText },
                    },
                } = withPreviewData(staticData);

                return (
                    <RequestAQuoteButton {...props} text={ctaButtonText.text} />
                );
            }}
        />
    );
}

export default StaticRequestAQuoteButton;
