import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { FixedImage } from './Image';
import MediaCard from './MediaCard';

const useStyles = makeStyles((theme) => ({
    image: ({ aspectRatio }) =>
        aspectRatio === 'static'
            ? {
                  paddingTop: '75%', // 4:3 aspect ratio
              }
            : {
                  [theme.breakpoints.down('sm')]: {
                      paddingTop: '120%', // 6:5 aspect ratio
                  },
                  [theme.breakpoints.up('md')]: {
                      paddingTop: '75%', // 4:3 aspect ratio
                  },
              },
}));

function ImageCard({
    image,
    imageWidth,
    imageStyles,
    aspectRatio,
    ...otherProps
}) {
    const { image: imageClass, ...otherClasses } = otherProps.classes || {};
    const classes = useStyles({ classes: { image: imageClass }, aspectRatio });

    return (
        <MediaCard {...otherProps} classes={otherClasses}>
            <FixedImage
                className={classes.image}
                image={image}
                width={imageWidth}
                style={{
                    width: '100%',
                    height: '100%',
                    ...imageStyles,
                }}
            />
        </MediaCard>
    );
}

ImageCard.propTypes = {
    image: PropTypes.object.isRequired,
    imageWidth: PropTypes.number,
    imageStyles: PropTypes.object,
    aspectRatio: PropTypes.oneOf(['static', 'dynamic']),
};

ImageCard.defaultProps = {
    imageWidth: 800,
    imageStyles: {},
    aspectRatio: 'dynamic',
};

export default ImageCard;
