import { useEffect } from 'react';

export default function useError(handler) {
    useEffect(() => {
        if (!window) {
            return undefined;
        }

        (window.addEventListener || window.on)('error', handler);

        return () =>
            (window.removeEventListener || window.off)('error', handler);
    }, [handler]);
}
