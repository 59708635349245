import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { mobileNavBreakpoint } from './NavBar/constants';
import Section from './Section';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 1,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        textAlign: 'left',
    },
    text: {
        color: theme.palette.common.white,
    },
    showOnMobile: {
        [`@media (min-width:${mobileNavBreakpoint + 1}px)`]: {
            display: 'none',
        },
    },
    button: {
        marginTop: theme.spacing(2),
    },
}));

function AltHeroText(props) {
    const { title, subtitle } = props;
    const classes = useStyles(props);

    return (
        <Section classes={{ root: classes.root }}>
            {title && (
                <Typography variant="h1" className={classes.text}>
                    {title}
                </Typography>
            )}
            {subtitle && (
                <Typography variant="h2" className={classes.text}>
                    {subtitle}
                </Typography>
            )}
        </Section>
    );
}

AltHeroText.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

AltHeroText.defaultProps = {
    title: undefined,
    subtitle: undefined,
};

export default AltHeroText;
