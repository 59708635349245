import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { blue } from '@serviceslabs/material-ui-pro/colors';

const colors = (theme) =>
    Object.freeze({
        default: theme.palette.grey[400],
        primary: blue[200],
    });

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'relative',
        textAlign: 'center',
    },
    divider: {
        position: 'absolute',
        backgroundColor: (props) => colors(theme)[props.variant],
        height: 1,
        top: 0,
        width: '100%',
        bottom: 0,
    },
}));

const Divider = ({ variant, ...otherProps }) => {
    const classes = useStyles({ classes: otherProps.classes, variant });

    return (
        <div className={classes.root}>
            <div className={classes.divider} />
        </div>
    );
};

Divider.propTypes = {
    variant: PropTypes.oneOf(['default', 'primary']),
};

Divider.defaultProps = {
    variant: 'default',
};

export default Divider;
