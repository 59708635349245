import React from 'react';
import PropTypes from 'prop-types';
import { Link as MuiLink } from '@material-ui/core';
import { Link as GatsbyLink } from 'gatsby';

import { link as prismicLink } from '../../../shapes/prismic';

function PrismicLink({ link, children, ...props }) {
    return link.link_type === 'Document' ? (
        <MuiLink
            component={GatsbyLink}
            to={link.url}
            target={link.target}
            {...props}
        >
            {children}
        </MuiLink>
    ) : (
        <MuiLink href={link.url} target={link.target} {...props}>
            {children}
        </MuiLink>
    );
}

PrismicLink.propTypes = {
    link: prismicLink.isRequired,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
};

PrismicLink.defaultProps = {
    children: undefined,
};

export default PrismicLink;
