import getWindow from '../util/window';

export function track(...params) {
    const window = getWindow();
    if (!window.analytics) return;
    window.analytics.track(...params);
}

export function identify(...params) {
    const window = getWindow();
    if (!window.analytics) return;
    window.analytics.identify(...params);
}
