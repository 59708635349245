import PrismicImage from '../models/prismicImage';

export const parseBmqPricingCallouts = (items) =>
    items.map((item) => ({
        title: item.bmq_pricing_callout_title,
        text: item.bmq_pricing_callout_text,
    }));

export const parseValuePropCallouts = (items) =>
    items.map((item) => ({
        description: item.description.html,
        title: item.title.text,
        iconImage: new PrismicImage(item.icon),
        link: item.link ? item.link.url : undefined,
        linkTitle: item.linkTitle ? item.linkTitle.text : undefined,
    }));

export const parseFeaturedProjects = (items) =>
    items.map((item) => ({
        title: item.project_title && item.project_title.text,
        description: item.project_description && item.project_description.text,
        image: new PrismicImage(item.image),
        link: item.project_link,
    }));

export const parseProjectTypeCallouts = (items) =>
    items.map((item) => ({
        title: item.title && item.title.text,
        description: item.description && item.description.text,
        image: new PrismicImage(item.image),
        link: item.link,
    }));

export const parseSimilarProjects = (items) =>
    items.map((item) => ({
        description: item.title && item.title.text,
        image: new PrismicImage(item.image),
        link: item.link,
    }));

export function parseTestimonials(items) {
    if (!items) {
        return [];
    }

    return items.map((item) => ({
        customerName: item.customer_name.text,
        quote: item.quote.text,
    }));
}

export function parsePressTestimonials(items) {
    if (!items) {
        return [];
    }
    return items.map((item) => ({
        pressNote: item.press_release_note,
        publisherLogo: new PrismicImage(item.publisher_logo),
    }));
}

export function parseCityTestimonials(items) {
    if (!items) {
        return [];
    }

    return items.map((item) => ({
        customerName: item.customerName.text,
        quote: item.quote.text,
    }));
}

export const parseImageGridData = (items) => {
    return items
        .map((item) => ({
            image: new PrismicImage(item.image),
            isFeatured: item.isFeatured,
        }))
        .filter(({ image }) => Boolean(image.url));
};

export const parseImageRowData = (items) => {
    return items
        .map((item) => ({
            image: new PrismicImage(item.image),
        }))
        .filter(({ image }) => Boolean(image.url));
};

export function parseNavbarLinks(links) {
    if (!links) return [];

    return links.map((link) => ({
        type: link.link && link.link.link_type,
        to: link.link && link.link.url,
        text: link.linkTitle.text,
    }));
}

export function parseLocalePins(pins) {
    if (!pins) {
        return [];
    }

    return pins.map((pin) => ({
        cityName: (pin.city_name || {}).text,
        latitude: pin.latitude,
        longitude: pin.longitude,
    }));
}

export function parseBmqModelData({
    zip_title: zipTitle,
    zip_header_text: zipHeaderText,
    zip_cta_button_text: zipCtaButtonText,
    bad_zip_title: badZipTitle,
    bad_zip_header_text: badZipHeaderText,
    bad_zip_sign_up_label: badZipSignUpLabel,
    bad_zip_cta_button_text: badZipCtaButtonText,
    bad_zip_partner_reference_label: badZipPartnerReferenceLabel,
    partner_links: badZipPartnerLinks,
    bad_zip_thank_you_message_label: badZipThankYouMessageLabel,
    contact_title: contactTitle,
    contact_header_text: contactHeaderText,
    contact_info_subheader: contactInfoSubheader,
    project_details_subheader: projectDetailsSubheader,
    project_type_placeholder: projectTypePlaceholder,
    customer_type_header: customerTypeSubheader,
    architect_type: architectTypeHeader,
    homeowner_type: homeownerTypeHeader,
    other_type: otherTypeHeader,
    notes_placeholder: notesPlaceholder,
    project_types: projectTypes,
    disclaimer_text: disclaimerText,
    contact_cta_button_text: contactCtaButtonText,
    confirmation_title: confirmationTitle,
    confirmation_header_text: confirmationHeaderText,
}) {
    return {
        zipCode: {
            title: zipTitle.text,
            headerHtml: zipHeaderText.html,
            ctaButtonText: zipCtaButtonText.text,
        },
        badZipCode: {
            title: badZipTitle.text,
            headerHtml: badZipHeaderText.html,
            signUpLabelHtml: badZipSignUpLabel.html,
            ctaButtonText: badZipCtaButtonText.text,
            partnerReferenceLabelHtml: badZipPartnerReferenceLabel.html,
            partnerLinks: badZipPartnerLinks.map(
                ({ partner_link: partnerLink, partner_logo: partnerLogo }) => ({
                    link: partnerLink.url,
                    logoImage: new PrismicImage(partnerLogo),
                })
            ),
            thankYouMessageLabelHtml: badZipThankYouMessageLabel.html,
        },
        contactInfo: {
            title: contactTitle.text,
            headerHtml: contactHeaderText.html,
            contactInfoSubheader: contactInfoSubheader.text,
            projectDetailsSubheader,
            projectTypePlaceholder,
            customerTypeSubheader,
            architectTypeHeader,
            homeownerTypeHeader,
            otherTypeHeader,
            notesPlaceholder,
            disclaimerHtml: disclaimerText.html,
            projectTypes: projectTypes.map(
                ({ project_type_option: projectTypeOption }) =>
                    projectTypeOption.text
            ),
            ctaButtonText: contactCtaButtonText.text,
        },
        confirmation: {
            title: confirmationTitle.text,
            headerHtml: confirmationHeaderText.html,
        },
    };
}

export function parseRequestAQuoteData({
    zip_title: zipTitle,
    zip_header_text: zipHeaderText,
    zip_cta_button_text: zipCtaButtonText,
    bad_zip_title: badZipTitle,
    bad_zip_header_text: badZipHeaderText,
    bad_zip_sign_up_label: badZipSignUpLabel,
    bad_zip_cta_button_text: badZipCtaButtonText,
    bad_zip_partner_reference_label: badZipPartnerReferenceLabel,
    partner_links: badZipPartnerLinks,
    bad_zip_thank_you_message_label: badZipThankYouMessageLabel,
    contact_title: contactTitle,
    contact_header_text: contactHeaderText,
    contact_info_subheader: contactInfoSubheader,
    project_details_subheader: projectDetailsSubheader,
    project_type_placeholder: projectTypePlaceholder,
    notes_placeholder: notesPlaceholder,
    project_types: projectTypes,
    disclaimer_text: disclaimerText,
    contact_cta_button_text: contactCtaButtonText,
    confirmation_title: confirmationTitle,
    confirmation_header_text: confirmationHeaderText,
}) {
    return {
        zipCode: {
            title: zipTitle.text,
            headerHtml: zipHeaderText.html,
            ctaButtonText: zipCtaButtonText.text,
        },
        badZipCode: {
            title: badZipTitle.text,
            headerHtml: badZipHeaderText.html,
            signUpLabelHtml: badZipSignUpLabel.html,
            ctaButtonText: badZipCtaButtonText.text,
            partnerReferenceLabelHtml: badZipPartnerReferenceLabel.html,
            partnerLinks: badZipPartnerLinks.map(
                ({ partner_link: partnerLink, partner_logo: partnerLogo }) => ({
                    link: partnerLink.url,
                    logoImage: new PrismicImage(partnerLogo),
                })
            ),
            thankYouMessageLabelHtml: badZipThankYouMessageLabel.html,
        },
        contactInfo: {
            title: contactTitle.text,
            headerHtml: contactHeaderText.html,
            contactInfoSubheader: contactInfoSubheader.text,
            projectDetailsSubheader,
            projectTypePlaceholder,
            notesPlaceholder,
            disclaimerHtml: disclaimerText.html,
            projectTypes: projectTypes.map(
                ({ project_type_option: projectTypeOption }) =>
                    projectTypeOption.text
            ),
            ctaButtonText: contactCtaButtonText.text,
        },
        confirmation: {
            title: confirmationTitle.text,
            headerHtml: confirmationHeaderText.html,
        },
    };
}
export function parseInfoBarItems(items) {
    if (!items) return [];
    return items.map((item) => ({
        title: item.title.text,
        body: item.body.text,
    }));
}

export function parseSeeMore(text, link) {
    return {
        text: text ? text.text : undefined,
        url: link ? link.url : undefined,
    };
}

export function parseTeamRow(teamRow) {
    return (teamRow || [])
        .map((member) => ({
            bioHtml: member.text.html,
            name: member.name.text,
            title: member.team_member_title,
            image: member.icon ? new PrismicImage(member.icon) : undefined,
        }))
        .filter(({ bioHtml, name, image }) => image && name && bioHtml);
}

export function parsePortfolioImages(items) {
    return items.map((item) => ({
        city: item.city,
        image: new PrismicImage(item.image),
        project: item.project,
        caption: item.caption ? item.caption.text : undefined,
        link: item.link,
    }));
}

export function parseMemberships(memberships) {
    return memberships.map((membership) => ({
        image: new PrismicImage(membership.image),
        text: membership.text ? membership.text.text : undefined,
    }));
}

export function parseValuesGrid(items) {
    if (!items) {
        return [];
    }
    return items.map((item) => ({
        valueHeading: item.value_heading,
        valueDescription: item.value_description,
        valueCheckmark: new PrismicImage(item.value_check_mark),
    }));
}

export function parseLeaders(items) {
    if (!items) {
        return [];
    }
    return items.map((item) => ({
        team_member_name: item.team_member_name,
        bio: item.bio,
        icon: new PrismicImage(item.icon),
        designation: item.designation,
    }));
}

export function parseConstructionExpertRow(items) {
    if (!items) {
        return [];
    }
    return items.map((item) => ({
        team_member_name: item.construction_expert_name,
        bio: item.construction_expert_description,
        icon: new PrismicImage(item.construction_expert_image),
        designation: item.construction_expert_title,
    }));
}

export function parsePressListing(items) {
    if (!items) {
        return [];
    }
    // This array needs to be reversed because press listings can get very long and in Prismic new content gets added to the end of the list.
    items.reverse();
    return items.map((item) => ({
        pressNoteContent: item.press_note_content,
        pressNoteHeadline: item.press_note_headline,
        pressNoteReleaseDate: item.press_note_release_date,
        publisherLogo: new PrismicImage(item.publisher_logo),
        readMoreLink: item.read_more_link,
    }));
}
export function parseProValues(items) {
    if (!items) {
        return [];
    }
    return items.map((item) => ({
        valueCheckmark: new PrismicImage(item.value_image),
        valueDescription: item.value_description,
        valueHeading: item.value_heading,
    }));
}

export function parseCallsToAction(items) {
    if (!items) {
        return [];
    }

    return items.map((item) => ({
        buttonText: item.cta_button_text,
        link: item.cta_link,
        text: item.cta_text,
        image: new PrismicImage(item.cta_image),
    }));
}
