import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import { track } from '../../../services/segment';

const useStyles = makeStyles((theme) => ({
    root: {},
    button: {},
    label: {
        whiteSpace: 'nowrap',
        color: theme.palette.common.black,
    },
}));

function SigninButton({ text, contextDescription, size, href, ...otherProps }) {
    const classes = useStyles({ classes: otherProps.classes });

    return (
        <div className={classes.root}>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                    track('Sign In button clicked', {
                        context: contextDescription,
                        ctaText: text,
                    });
                }}
                size={size}
                classes={{ label: classes.label, root: classes.button }}
                component={Link}
                to={href}
            >
                {text}
            </Button>
        </div>
    );
}

SigninButton.propTypes = {
    text: PropTypes.string,
    contextDescription: PropTypes.string,
    size: PropTypes.string,
    href: PropTypes.string,
};

SigninButton.defaultProps = {
    text: 'Sign In',
    contextDescription: undefined,
    size: 'medium',
    href: 'https://arches.pro.com',
};

export default SigninButton;
