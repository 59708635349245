import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image/withIEPolyfill';
import PrismicImage from '../../../models/prismicImage';

export default function FixedImage({ image, width, height, ...props }) {
    return (
        <Img
            {...props}
            alt={image.alt}
            title={image.alt}
            fixed={image.fixed({ width, height })}
        />
    );
}

FixedImage.propTypes = {
    image: PropTypes.instanceOf(PrismicImage).isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
};

FixedImage.defaultProps = {
    width: undefined,
    height: undefined,
};
