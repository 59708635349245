import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1),
        },

        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    container: {
        width: '100%',
    },
}));

function CarouselContent(props) {
    const { children, ...otherProps } = props;
    const classes = useStyles({ classes: otherProps.classes });

    return (
        <Container className={classes.root}>
            <div className={classes.container}>{children}</div>
        </Container>
    );
}

CarouselContent.propTypes = {
    classes: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
};

CarouselContent.defaultProps = {
    classes: undefined,
};

export default CarouselContent;
