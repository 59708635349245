import React from 'react';
import PropTypes from 'prop-types';
import { Link, StaticQuery, graphql } from 'gatsby';
import { AppBar, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { blue } from '@serviceslabs/material-ui-pro/colors';
import proLogo from '../../../images/pro-logo-white.svg';

import Links from './Links';
import MobileMenu from './MobileMenu';

import useScroll from '../../../util/useScroll';
import { withPreviewData } from '../../../util/preview';
import {
    navHeight,
    mobileNavBreakpoint,
    forceSmallLogoBreakpoint,
    smallLogoHeight,
    largeLogoHeight,
} from './constants';

const query = graphql`
    query PlatformNavBarQuery {
        prismicPlatformNavBar {
            data {
                phoneNumber {
                    text
                }
                phoneNumberCta {
                    text
                }
            }
        }
    }
`;

const useStyles = makeStyles((theme) => ({
    root: {
        height: navHeight,
        backgroundColor: blue[700],
    },
    toolbar: {},
    logoLink: {
        marginRight: theme.spacing(8),
    },
    logo: {
        position: 'absolute',
        top: 12,
        zIndex: 1,
        transition: 'height 150ms ease',
        maxHeight: '12vh',
        [`@media (max-width:${forceSmallLogoBreakpoint}px)`]: {
            height: 36,
        },
        [`@media (min-width:${forceSmallLogoBreakpoint + 1}px)`]: {
            height: (props) =>
                props.showSmallLogo ? smallLogoHeight : largeLogoHeight,
        },
        [theme.breakpoints.up('md')]: {
            left: theme.spacing(16),
        },
        [theme.breakpoints.down('sm')]: {
            left: theme.spacing(6),
        },
    },
    showOnMobile: {
        [`@media (max-width:${mobileNavBreakpoint}px)`]: {
            display: 'flex',
            flex: '1 1 100%',
            height: navHeight,
        },
        [`@media (min-width:${mobileNavBreakpoint + 1}px)`]: {
            display: 'none',
        },
    },
    showOnDesktop: {
        [`@media (max-width:${mobileNavBreakpoint}px)`]: {
            display: 'none',
        },
        [`@media (min-width:${mobileNavBreakpoint + 1}px)`]: {
            display: 'flex',
            flex: '1 1 100%',
            height: navHeight,
        },
    },
}));

export function NavBarImpl({ scrollThreshold, data, ...otherProps }) {
    /**
     * We'll set a custom breakpoint at which we switch to the hamburger menu
     * as to avoid going to early (960px if we do the 'sm' breakpoint), but do
     * it before things start overlapping.
     */
    const currentScrollOffset = useScroll();

    const showSmallLogo =
        scrollThreshold <= 0 || scrollThreshold <= currentScrollOffset;

    const classes = useStyles({
        showSmallLogo,
        classes: otherProps.classes,
    });

    return (
        <AppBar position="sticky" className={classes.root}>
            <Toolbar className={classes.toolbar}>
                {/* show only on mobile */}
                <div id="mobile-nav-wrapper" className={classes.showOnMobile}>
                    <MobileMenu
                        phoneNumber={data.phoneNumber.text}
                        phoneNumberCta={data.phoneNumberCta.text}
                    >
                        <Links data={data} />
                    </MobileMenu>
                </div>
                <div id="desktop-nav-wrapper" className={classes.showOnDesktop}>
                    <Link to="/">
                        <img
                            className={classes.logo}
                            src={proLogo}
                            alt="Pro.com logo"
                        />
                    </Link>
                    <Links data={data} />
                </div>
            </Toolbar>
        </AppBar>
    );
}

NavBarImpl.propTypes = {
    classes: PropTypes.object,
    scrollThreshold: PropTypes.number,
    data: PropTypes.object.isRequired,
};

NavBarImpl.defaultProps = {
    scrollThreshold: 50,
    classes: undefined,
};

function StaticNavBar(props) {
    return (
        <StaticQuery
            query={query}
            render={(staticData) => {
                const {
                    prismicPlatformNavBar: { data },
                } = withPreviewData(staticData);

                return <NavBarImpl {...props} data={data} />;
            }}
        />
    );
}

export default StaticNavBar;
