import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography } from '@material-ui/core';
import SectionHeader from './SectionHeader';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(4),
    },
    title: {
        textTransform: 'uppercase',
    },
    text: {
        marginTop: theme.spacing(2),
    },
}));

function BubbleCallout(props) {
    const { title, text } = props;
    const classes = useStyles(props);

    return (
        <Card className={classes.root}>
            <SectionHeader title={title} />
            <Typography className={classes.text}>{text}</Typography>
        </Card>
    );
}

BubbleCallout.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default BubbleCallout;
