import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';

const colors = Object.freeze({
    default: () => undefined,
    primary: (theme) => theme.palette.primary.light,
    secondary: (theme) => theme.palette.grey[50],
});

const useStyles = makeStyles((theme) => {
    return {
        root: {
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(8, 3),
            },
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(8, 12),
            },
            textAlign: 'center',
            backgroundColor: (props) => colors[props.color](theme),
        },
        container: {},
    };
});

function Section(props) {
    const { children, color, ...otherProps } = props;
    const classes = useStyles({ color, classes: otherProps.classes });

    return (
        <section className={classes.root}>
            <Container className={classes.container} maxWidth="lg">
                {children}
            </Container>
        </section>
    );
}

Section.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
    color: PropTypes.oneOf(Object.keys(colors)),
    classes: PropTypes.object,
};

Section.defaultProps = {
    color: 'default',
    classes: undefined,
};

export default Section;
