import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { mobileNavBreakpoint } from './constants';

import StaticSigninButton from '../SignIn/StaticSigninButton';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        flexShrink: 1,
        justifyContent: 'flex-end',
        [[`@media (min-width:${mobileNavBreakpoint + 1}px)`]]: {
            display: 'flex',
        },
    },
    buttonItem: {
        [[`@media (max-width:${mobileNavBreakpoint}px)`]]: {
            marginTop: theme.spacing(2),
        },
        [[`@media (min-width:${mobileNavBreakpoint + 1}px)`]]: {
            marginLeft: theme.spacing(2),
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
}));

function Links({ classes: propClasses }) {
    const classes = useStyles({ classes: propClasses });

    return (
        <>
            <div className={classes.root}>
                <div className={classes.buttonItem}>
                    <StaticSigninButton contextDescription="nav" />
                </div>
            </div>
        </>
    );
}

Links.propTypes = {
    classes: PropTypes.object,
};

Links.defaultProps = {
    classes: undefined,
};

export default Links;
