import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    parseIncompletePhoneNumber,
    formatIncompletePhoneNumber,
} from 'libphonenumber-js';
import { TextField } from '@material-ui/core';

function hasTooManyDigits(parsedNumber) {
    if (!parsedNumber) {
        return false;
    }
    return parsedNumber.charAt(0) === '1'
        ? parsedNumber.length > 11
        : parsedNumber.length > 10;
}

function PhoneNumberTextField({
    value,
    onChange,
    limitInputDigits,
    textFieldComponent,
    ...otherProps
}) {
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState();
    const Component = textFieldComponent;

    useEffect(() => {
        setFormattedPhoneNumber(formatIncompletePhoneNumber(value, 'US'));
    }, [value]);

    function handleChange(newVal) {
        const parsedNumber = parseIncompletePhoneNumber(newVal);
        if (!limitInputDigits || !hasTooManyDigits(parsedNumber)) {
            onChange(parsedNumber);
        }
    }

    return (
        <Component
            type="tel"
            value={formattedPhoneNumber || ''}
            onChange={(event) => handleChange(event.target.value)}
            {...otherProps}
        />
    );
}

PhoneNumberTextField.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    limitInputDigits: PropTypes.bool,
    textFieldComponent: PropTypes.elementType,
};

PhoneNumberTextField.defaultProps = {
    limitInputDigits: false,
    value: '',
    textFieldComponent: TextField,
};

export default PhoneNumberTextField;
