import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

import { blue } from '@serviceslabs/material-ui-pro/colors';

import PrismicImage from '../../models/prismicImage';

import HeroOverlay from './HeroOverlay';
import { FluidImage } from './Image';
import { navHeight, forceSmallLogoBreakpoint } from './NavBar/constants';

const useStyles = makeStyles(({ breakpoints }) => ({
    root: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        height: ({ image: { url } }) => url && `calc(90vh - ${navHeight}px)`,
        minHeight: '450px',
    },
    bgImage: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: `calc(90vh - ${navHeight}px)`,
        minHeight: '450px',
    },
    overlay: ({ image: { url } }) => {
        const base = {
            display: 'flex',
            [breakpoints.up('md')]: {
                flexDirection: 'column',
                justifyContent: 'center',
            },
        };

        const hasImage = {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            boxShadow: `-300px 0 white,
                        300px 0 white,
                        inset 0 -300px 180px -80px
                        rgba(0, 0, 0, 0.8)`,
        };

        const noImage = {
            backgroundColor: blue[800],
            [`@media (min-width:${forceSmallLogoBreakpoint}px)`]: {
                paddingTop: 40,
            },
            width: '100%',
        };

        return { ...base, ...(url ? hasImage : noImage) };
    },
}));

export function HeroImpl({ children, image = {}, ...otherProps }) {
    const classes = useStyles({ classes: otherProps.classes, image });
    const theme = useTheme();
    const objectPosition = useMediaQuery(theme.breakpoints.down('xs'))
        ? '65% 50%'
        : '50% 50%';

    return (
        <section className={classes.root}>
            {image.url && (
                <FluidImage
                    className={classes.bgImage}
                    image={image}
                    objectPosition={objectPosition}
                    maxWidth={1920}
                />
            )}
            <div className={classes.overlay}>{children}</div>
        </section>
    );
}

HeroImpl.propTypes = {
    image: PropTypes.instanceOf(PrismicImage),
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
};

HeroImpl.defaultProps = {
    image: undefined,
    children: undefined,
};

function Hero({ showOverlay, ...otherProps }) {
    return showOverlay ? (
        <HeroOverlay>
            <HeroImpl {...otherProps} />
        </HeroOverlay>
    ) : (
        <HeroImpl {...otherProps} />
    );
}

Hero.propTypes = {
    showOverlay: PropTypes.bool,
};

Hero.defaultProps = {
    showOverlay: false,
};

export default Hero;
