import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, DialogActions, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: 'column',
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(4),
    },
    actionContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        paddingBottom: theme.spacing(1),
    },
    errorMessage: {
        color: theme.palette.error.main,
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    disclaimer: {
        color: theme.palette.grey[500],
        padding: theme.spacing(2, 0, 4),
        textAlign: 'center',
        width: '100%',
        '& a': {
            color: 'inherit',
        },
    },
    pageIndicator: {
        color: theme.palette.grey[500],
        fontSize: '0.875rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
}));

function DialogFooter({
    onBack,
    onNext,
    nextDisabled,
    backDisabled,
    nextLabel,
    backLabel,
    pageNumber,
    totalPages,
    error,
    disclaimer,
    ...otherProps
}) {
    const hasPageNumbers = !!(totalPages && pageNumber);
    const classes = useStyles({ classes: otherProps.classes, hasPageNumbers });

    return (
        <DialogActions className={classes.root}>
            {error && (
                <Typography
                    variant="body2"
                    className={classes.errorMessage}
                    component="div"
                >
                    {error}
                </Typography>
            )}
            {disclaimer && (
                <Typography
                    variant="body2"
                    className={classes.disclaimer}
                    component="div"
                >
                    {disclaimer}
                </Typography>
            )}
            <div className={classes.actionContainer}>
                <div>
                    {onBack && (
                        <Button
                            className={classes.backButton}
                            onClick={onBack}
                            color="primary"
                            id="back"
                            disabled={backDisabled}
                        >
                            {backLabel}
                        </Button>
                    )}
                    {onNext && (
                        <Button
                            variant="contained"
                            onClick={onNext}
                            disabled={nextDisabled}
                            color="primary"
                            id="next"
                        >
                            {nextLabel}
                        </Button>
                    )}
                </div>
            </div>
            <div className={classes.actionContainer}>
                {hasPageNumbers ? (
                    <Typography
                        variant="body1"
                        className={classes.pageIndicator}
                    >
                        {pageNumber} of {totalPages}
                    </Typography>
                ) : undefined}
            </div>
        </DialogActions>
    );
}

DialogFooter.propTypes = {
    onBack: PropTypes.func,
    onNext: PropTypes.func,
    nextDisabled: PropTypes.bool,
    backDisabled: PropTypes.bool,
    nextLabel: PropTypes.string,
    backLabel: PropTypes.string,
    pageNumber: PropTypes.number,
    totalPages: PropTypes.number,
    error: PropTypes.string,
    disclaimer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

DialogFooter.defaultProps = {
    onBack: undefined,
    onNext: undefined,
    nextDisabled: false,
    backDisabled: false,
    nextLabel: 'Next',
    backLabel: 'Back',
    pageNumber: undefined,
    totalPages: undefined,
    error: undefined,
    disclaimer: undefined,
};

export default DialogFooter;
