import { createServiceRequest as createServiceRequestApi } from '../api/serviceRequests';
import { isValidZipCode as isValidZipCodeApi } from '../api/zipCodes';

import { getUserAgent, getDocument } from '../util/window';
import {
    getUtmParameters,
    getSessionId,
    getLandingPageLocation,
} from './session';
import { SrSubmissionEvent, sendGoogleAnalyticsEvent } from './googleAnalytics';

export async function isValidZipCode(zipCode) {
    try {
        const result = await isValidZipCodeApi(zipCode);
        return result;
    } catch (err) {
        // if we don't know a zipcode, 100% accurate to say we don't cover
        return false;
    }
}

export function isValidRequest(customer, serviceAddress) {
    const { firstName, lastName, phoneNumber, emailAddress } = customer;
    const { zipCode } = serviceAddress;

    return Boolean(
        firstName && lastName && phoneNumber && emailAddress && zipCode
    );
}

export async function createServiceRequest({
    customer,
    serviceAddress,
    projectType,
    notes,
    channel,
    isBmq,
}) {
    const { category, action, label, value } = SrSubmissionEvent;
    sendGoogleAnalyticsEvent(category, action, label, value);
    const { referrer } = getDocument();
    const { origin, href, search, pathname } = getLandingPageLocation();

    const result = await createServiceRequestApi({
        customer,
        serviceAddress,
        projectType,
        notes,
        utm: {
            ...(getUtmParameters() || {}),
        },
        channel,
        isBmq,
        referrer,
        landingPageLocation: {
            origin,
            href,
            search,
            pathname,
        },
        clientType: getUserAgent(),
        sessionId: getSessionId(),
    });

    return result;
}
