import getWindow from '../util/window';

export const SrSubmissionEvent = {
    category: 'SR',
    action: 'Click',
    label: 'SR-Submission',
    value: 1,
};

export function sendGoogleAnalyticsEvent(category, action, label, value) {
    const window = getWindow();
    if (window.ga) {
        window.ga('send', 'event', category, action, label, value);
    }
}
