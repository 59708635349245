import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, Typography } from '@material-ui/core';

import Caption from './Caption';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
        width: '100%',
        height: '100%',
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    imageContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    imageOverlay: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        '-webkit-transform': 'translate(-50%,-50%)',
        '-ms-transform': 'translate(-50%,-50%)',
        transform: 'translate(-50%,-50%)',
        margin: 'auto',
        width: '58%',
        minWidth: 220,
        minHeight: 86,
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(0, 2),
        opacity: 0.9,
        boxShadow: theme.shadows[2],
        '&:hover': {
            boxShadow: theme.shadows[5],
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    imageOverlayText: {
        fontFamily: 'Gibson',
        fontWeight: 600,
        [theme.breakpoints.up('sm')]: {
            lineHeight: 1.25,
            fontSize: 22,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
            lineHeight: 1.33,
        },
    },
    paragraph: {
        padding: theme.spacing(2, 0),
        textAlign: 'left',
    },
    captionTitle: {
        paddingTop: theme.spacing(2),
        textAlign: 'left',
        fontWeight: 600,
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
    button: {
        paddingBottom: theme.spacing(2),
    },
}));

export function ImageOverlay({ classes, title }) {
    return (
        <div className={classes.imageOverlay}>
            <Typography variant="button" className={classes.imageOverlayText}>
                {title}
            </Typography>
        </div>
    );
}

ImageOverlay.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
};

function MediaCard(props) {
    const {
        children,
        overlayText,
        captionTitle,
        caption,
        link,
        linkVariant,
        linkTitle,
        captionVariant,
        captionTitleVariant,
        ...otherProps
    } = props;
    const classes = useStyles({ classes: otherProps.classes });

    return (
        <div className={classes.root}>
            <div className={classes.imageContainer}>
                {children}
                {overlayText && (
                    <ImageOverlay classes={classes} title={overlayText} />
                )}
            </div>

            {captionTitle && (
                <div>
                    <Typography
                        variant={captionTitleVariant}
                        classes={{ root: classes.captionTitle }}
                    >
                        {captionTitle}
                    </Typography>
                </div>
            )}
            {caption && (
                <div className={classes.content}>
                    <Caption
                        classes={{ root: classes.paragraph }}
                        caption={caption}
                        variant={captionVariant}
                    />
                    {link && (
                        <div className={classes.button}>
                            <Button
                                size="medium"
                                color="secondary"
                                variant={linkVariant}
                                href={link}
                            >
                                {linkTitle}
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

MediaCard.propTypes = {
    overlayText: PropTypes.string,
    caption: PropTypes.string,
    captionVariant: PropTypes.string,
    captionTitle: PropTypes.string,
    captionTitleVariant: PropTypes.string,
    link: PropTypes.string,
    linkVariant: PropTypes.string,
    linkTitle: PropTypes.string,
    classes: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
};

MediaCard.defaultProps = {
    overlayText: undefined,
    caption: undefined,
    captionVariant: 'body1',
    captionTitle: undefined,
    captionTitleVariant: 'body1',
    link: undefined,
    linkVariant: 'contained',
    linkTitle: undefined,
    classes: undefined,
    children: undefined,
};

export default MediaCard;
