import { useState, useEffect } from 'react';

import getWindow from './window';

function getSize() {
    const window = getWindow();
    const { innerWidth, innerHeight, document } = window;

    // Pull the width of the window minus any scrollbar width, and use
    // that if available, falling back to the window innerWidth, which
    // will include the width of the scrollbar.
    const docWidth = document
        ? document.documentElement.clientWidth || document.body.clientWidth
        : undefined;

    const docHeight = document
        ? document.documentElement.clientHeight || document.body.clientHeight
        : undefined;

    return { width: docWidth || innerWidth, height: docHeight || innerHeight };
}

export default function useSize() {
    const window = getWindow();

    const [size, setSize] = useState(getSize());

    useEffect(() => {
        const handler = () => setSize(getSize);

        window.addEventListener('resize', handler);

        /**
         * Ideally we'd listen for the page onload, but it seems that's
         * called before this hook is initiated. We do this so we can
         * set the size once the full page is loaded and it's
         * been established if the page is going to scroll.
         *
         * By setting a timeout to run with a 0ms delay, we'll queue into
         * the callback stack and that seems to run the getSize handler
         * after enough other components have run and the scrollbar is present.
         */
        setTimeout(handler, 0);

        return () => window.removeEventListener('resize', handler);
    }, []);

    return size;
}
