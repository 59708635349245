import axios from 'axios';

import { getGlobal } from '../util/globals';

const apiUrl = () => getGlobal('apiUrl');

export async function createServiceRequest({
    customer,
    serviceAddress,
    projectType,
    notes,
    utm,
    isBmq,
    channel,
    referrer,
    landingPageLocation,
    clientType,
    sessionId,
}) {
    const result = await axios.post(`${apiUrl()}/service-requests/`, {
        customer,
        serviceAddress,
        projectType,
        notes,
        utm,
        isBmq,
        channel,
        referrer,
        landingPageLocation,
        clientType,
        sessionId,
    });
    return result.data;
}
